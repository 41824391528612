import React from 'react';

import {
    HomeIcon,
    LocationIcon,
    HeadphonesIcon,
    ShoppingIcon,
    SpannerIcon,
    CircleIcon,
    CircleLine,
} from './icons';
import {bgColorClassnames, textColorClassnames} from './utils';

const CATEGORIES = [
    'services',
    'facilities',
    'travel',
    'entertainment',
    'products',
    'excluded',
    'default',
] as const;

export type CategoryThemeType = (typeof CATEGORIES)[number];

const CATEGORISATION_THEMES = {
    services: {
        key: 'services',
        icon: <SpannerIcon />,
    },
    facilities: {
        key: 'facilities',
        icon: <HomeIcon />,
    },
    travel: {
        key: 'travel',
        icon: <LocationIcon />,
    },
    entertainment: {
        key: 'entertainment',
        icon: <HeadphonesIcon />,
    },
    products: {
        key: 'products',
        icon: <ShoppingIcon />,
    },
    excluded: {
        key: 'excluded',
        icon: <CircleLine />,
    },
    default: {
        key: 'default',
        icon: <CircleIcon />,
    },
} as Record<CategoryThemeType, CategoryTheme>;

export type CategoryTheme = {
    key: CategoryThemeType;
    icon: JSX.Element;
    color: string;
    bgColorClassname: string;
};

function isValidCategory(key: string): key is CategoryThemeType {
    return CATEGORIES.includes(key as CategoryThemeType);
}

export function getDefaultCategorisationTheme() {
    return CATEGORISATION_THEMES['default'];
}

export function getCategorisationTheme(taxonomyTermKey: string) {
    if (isValidCategory(taxonomyTermKey)) {
        return CATEGORISATION_THEMES[taxonomyTermKey];
    }

    return getDefaultCategorisationTheme();
}

type CategorisationThemeIconProps = {
    theme: CategoryTheme;
};
export function CategorisationThemeIcon({theme}: CategorisationThemeIconProps) {
    if (theme.key === 'default') {
        return (
            <div className={'flex shrink-0 self-center justify-center h-8 w-8'}>{theme.icon}</div>
        );
    } else {
        return (
            <div
                className={`flex shrink-0 items-center justify-center self-center h-8 w-8 rounded-full ${bgColorClassnames(
                    'table-header',
                )}`}
            >
                <div className={`w-4 h-4 ${textColorClassnames('white')}`}>{theme.icon}</div>
            </div>
        );
    }
}
