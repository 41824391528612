import React, {forwardRef} from 'react';
import classnames from 'classnames';

import {Text, textColorClassnames} from './utils';

type DetailTextProps = {
    children: React.ReactNode;
    inline?: boolean;
    colour?: Text;
    inherit?: boolean;
    bold?: boolean;
    underline?: boolean;
    size?: 'extra-small' | 'small' | 'medium';
    className?: string;
};

export type DetailTextRef = HTMLDivElement;

export const DetailText = forwardRef<DetailTextRef, DetailTextProps>(function DetailText(
    {
        children,
        inline = true,
        colour = 'default',
        bold = false,
        underline = false,
        inherit,
        className,
        size = 'medium',
    },
    ref,
) {
    return (
        <div
            ref={ref}
            className={classnames(className, !inherit && textColorClassnames(colour), {
                'text-10 leading-500': size === 'extra-small',
                'text-12 leading-500': size === 'small',
                'text-100 leading-500': size === 'medium',
                'font-body font-400': !bold,
                'font-heading font-500': bold,
                'inline-block': inline,
                underline: underline,
            })}
        >
            {children}
        </div>
    );
});
