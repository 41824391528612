import React from 'react';

import {DetailText} from './detail-text';
import Preset from '../preset';

const onColor = Preset.theme.extend.colors.action.minor[400];

type ToggleProps = {
    value: boolean;
    onToggle: () => void;
    onLabel?: string;
    offLabel?: string;
    testId?: string;
};
export function Toggle({value, onToggle, onLabel = 'On', offLabel = 'Off', testId}: ToggleProps) {
    return (
        <div className="flex flex-col items-start">
            <div className="flex items-center space-x-2 select-none">
                <DetailText inline={false} bold={!value}>
                    {React.createElement(React.Fragment, null, offLabel)}
                </DetailText>
                <svg
                    data-testid={testId}
                    className="cursor-pointer"
                    width="34"
                    height="14"
                    viewBox="0 0 34 20"
                    onClick={() => onToggle()}
                >
                    <rect
                        x="0"
                        y="3"
                        width="34"
                        height="14"
                        rx="7"
                        fill={onColor}
                        fillOpacity="0.1"
                    />
                    <circle
                        className="transition-transform"
                        style={{transform: `translateX(${value ? 14 : 0}px)`}}
                        cx="10"
                        cy="10"
                        r="10"
                        fill={onColor}
                    />
                </svg>
                <DetailText inline={false} bold={!!value}>
                    {React.createElement(React.Fragment, null, onLabel)}
                </DetailText>
            </div>
        </div>
    );
}
