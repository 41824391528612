import React from 'react';

import {Input, InputProps} from './input';
import {DropdownList} from './dropdown-list';
import {DetailText} from './detail-text';
import {LoadingSpinner} from './loading-spinner';
import {ChevronDownIcon} from './icons';
import {textColorClassnames} from './utils';

export type SelectListItem = {id: string; label: string};
export type SelectInputProps = Omit<
    InputProps,
    'type' | 'icon' | 'onPressIcon' | 'onChange' | 'value' | 'onBlur' | 'onFocus'
> & {
    items?: SelectListItem[];
    onChange: (item: SelectListItem) => void;
    value?: SelectListItem;
    isLoading?: boolean;
};

export type SelectInputReadonlyProps = Pick<InputProps, 'name'> & {
    value?: string;
};

export function SelectInputReadonly({value, ...inputProps}: SelectInputReadonlyProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    return (
        <div>
            <Input
                {...inputProps}
                showLabel={false}
                ref={inputRef}
                onChange={() => {
                    // do nothing
                }}
                value={value ?? ''}
                readOnly
            />
        </div>
    );
}
export function SelectInput({items, onChange, value, isLoading, ...inputProps}: SelectInputProps) {
    const [focused, setFocused] = React.useState(false);
    const [hoveringOverResults, setHoveringOverResults] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    return (
        <div>
            <Input
                {...inputProps}
                showLabel={false}
                ref={inputRef}
                onChange={() => {
                    // do nothing
                }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={value?.label ?? ''}
                icon={
                    <div className={`h-6 w-6 ${textColorClassnames('secondary')}`}>
                        {isLoading ? <LoadingSpinner /> : <ChevronDownIcon />}
                    </div>
                }
                onPressIcon={() => {
                    inputRef.current?.focus();
                }}
            />
            {(focused || hoveringOverResults) && items != null && (
                <div className="relative">
                    <div
                        className="absolute top-1 z-10 left-0 right-0"
                        onMouseEnter={() => setHoveringOverResults(true)}
                        onMouseLeave={() => setHoveringOverResults(false)}
                    >
                        <DropdownList
                            items={items}
                            itemKey={(item) => item.id}
                            renderItem={(item) => <DetailText inherit>{item.label}</DetailText>}
                            onClickItem={(item) => {
                                onChange(item);
                                inputRef.current?.blur();
                                setHoveringOverResults(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
