import React from 'react';

import {intercomTargetProps} from './intercom-target';

type FormControlContextProps = {
    isInvalid?: boolean;
    intercomTarget?: string;
};

export const FormControlContext = React.createContext<FormControlContextProps>({});

export function FormControl(props: FormControlContextProps & {children: React.ReactNode}) {
    return (
        <FormControlContext.Provider value={props}>
            <div {...intercomTargetProps(props.intercomTarget)}>{props.children}</div>
        </FormControlContext.Provider>
    );
}
