import React from 'react';

type TextProps = {
    children: React.ReactNode;
};

export function TruncatedStringWithEllipsis({children}: TextProps) {
    return (
        <div className="overflow-hidden grid">
            <div className="overflow-hidden w-full whitespace-nowrap overflow-ellipsis">
                {children}
            </div>
        </div>
    );
}
