import React from 'react';
import classnames from 'classnames';

import {textColorClassnames} from './utils';

type HighlightTextProps = {
    children: React.ReactNode;
    underline?: boolean;
};
export function HighlightText({children, underline}: HighlightTextProps) {
    return <span className={classnames(textColorClassnames('logo'), {underline})}>{children}</span>;
}
