import React from 'react';
import {useField} from 'formik';

import {
    Textarea as DesignSystemTextarea,
    TextareaProps,
    FormControl,
    FormErrorMessage,
} from '@sphericsio/design-system';

export function Textarea({name, ...props}: Pick<TextareaProps, 'name' | 'placeholder'>) {
    const [field, meta] = useField<string>({name});

    return (
        <FormControl isInvalid={meta.error != null}>
            <DesignSystemTextarea {...props} {...field} />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}
