import React from 'react';
import {useField} from 'formik';

import {
    Background,
    Text,
    Border,
    FormControl,
    FormErrorMessage,
    HStack,
    Pill,
} from '@sphericsio/design-system';

type PillRadioContextValue = {
    selectOption: (value: string) => void;
    selectedOption?: string;
};
const PillRadioContext = React.createContext<PillRadioContextValue>({
    selectOption: () => {
        throw new Error('invalid PillRadioContext');
    },
});

type PillRadioOptionProps = {
    value: string;
    children: React.ReactNode;
    borderSelected?: Border;
    borderUnselected?: Border;
    bgSelected?: Background;
    bgUnselected?: Background;
    textSelected?: Text;
    textUnselected?: Text;
};
export function PillRadioOption({
    value,
    borderSelected,
    borderUnselected,
    bgSelected,
    bgUnselected,
    textSelected,
    textUnselected,
    children,
}: PillRadioOptionProps) {
    const {selectOption, selectedOption} = React.useContext(PillRadioContext);
    return (
        <Pill
            borderSelected={borderSelected}
            borderUnselected={borderUnselected}
            bgSelected={bgSelected}
            bgUnselected={bgUnselected}
            textSelected={textSelected}
            textUnselected={textUnselected}
            isSelected={selectedOption === value}
            onChange={() => selectOption(value)}
        >
            {children}
        </Pill>
    );
}

type PillRadioProps = {
    value: string;
    onChange: (value: string) => void;
    children: React.ReactNode;
    errorMessage?: string;
};
export function PillRadio({children, onChange, value, errorMessage}: PillRadioProps) {
    const ctxValue: PillRadioContextValue = {
        selectOption: onChange,
        selectedOption: value === '' ? undefined : value,
    };
    return (
        <FormControl isInvalid={errorMessage != null}>
            <HStack>
                <PillRadioContext.Provider value={ctxValue}>{children}</PillRadioContext.Provider>
            </HStack>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
}

type FormikPillRadioProps = {
    name: string;
    children: React.ReactNode;
};
export function FormikPillRadio({name, children}: FormikPillRadioProps) {
    const [field, meta, helpers] = useField<string>({name});
    return (
        <PillRadio value={field.value} onChange={helpers.setValue} errorMessage={meta.error}>
            {children}
        </PillRadio>
    );
}
