import React from 'react';
import classnames from 'classnames';
import {useTextField} from '@react-aria/textfield';

import {FormControlContext} from './form-control';
import {outlineColorClassnames} from './utils';

export type TextareaProps = {
    name: string;
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onBlur?: (e: React.FocusEvent) => void;
    isInvalid?: boolean;
};
export function Textarea({
    value,
    name,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    isInvalid,
}: TextareaProps) {
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const formControlCtx = React.useContext(FormControlContext);
    const valid = !formControlCtx.isInvalid && !isInvalid;
    const {labelProps, inputProps} = useTextField(
        {
            inputElementType: 'textarea',
            name,
            placeholder,
            value,
            validationState: valid ? 'valid' : 'invalid',
            'aria-label': placeholder,
        },
        inputRef,
    );
    return (
        <div className="relative w-full">
            <label
                {...labelProps}
                className={classnames(
                    'absolute font-body text-xs bg-white top-0 left-3 px-1 transition-opacity leading-none',
                    {
                        'sr-only opacity-0': value === '',
                        'opacity-100': value !== '',
                    },
                )}
            >
                {placeholder}
            </label>
            <textarea
                {...(inputProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
                ref={inputRef}
                className={classnames(
                    'font-body text-sm rounded-xl border mt-2 py-2 px-4 w-full transition-colors focus:outline-none focus:ring-1',
                    (formControlCtx.isInvalid || isInvalid) &&
                        `${outlineColorClassnames('error')} focus:ring-red-400`,
                    {
                        'focus:ring-solid focus:border-solid':
                            !formControlCtx.isInvalid && !isInvalid,
                        'border-solid': !formControlCtx.isInvalid && !isInvalid && value !== '',
                    },
                )}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    );
}
