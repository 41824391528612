import {ApolloCache, ApolloError, TypedDocumentNode} from '@apollo/client';

import {ERROR_CODES} from './error-codes';

export function clearCurrentUser(cache: ApolloCache<any>) {
    cache.modify({
        fields: {
            currentUser: () => {
                return null;
            },
        },
    });
}

export function updateCurrentUser<T>(
    cache: ApolloCache<any>,
    data: T,
    fragment: TypedDocumentNode<T, unknown>,
) {
    cache.modify({
        fields: {
            currentUser: () => {
                const ref = cache.writeFragment({
                    data,
                    fragment,
                });

                return ref;
            },
        },
    });
}

export function purgeCompanyTransactions(cache: ApolloCache<any>) {
    cache.evict({fieldName: 'getCompanyTransactions'});
    cache.gc();
}

export function purgeFinancialAccounts(cache: ApolloCache<any>) {
    cache.evict({fieldName: 'getFinancialAccountsForCompany'});
    cache.gc();
}

type GetErrorOptions = {
    extraErrorCodes?: typeof ERROR_CODES;
    defaultMessage?: React.ReactNode;
};

export function getFirstErrorCode(error: ApolloError): string {
    const {
        graphQLErrors: [gqlError],
        networkError,
    } = error;
    if (gqlError) {
        return gqlError.message;
    } else if (networkError) {
        return 'NETWORK_ERROR';
    }
    return 'UNKNOWN_ERROR';
}

export function getErrorMessage(
    error: ApolloError,
    options: GetErrorOptions = {},
): React.ReactNode {
    const errorCode = getFirstErrorCode(error);
    const errorCodeMap = {
        ...ERROR_CODES,
        ...options.extraErrorCodes,
    };

    return errorCodeMap[errorCode] ?? options.defaultMessage ?? 'An unexpected error occured';
}

export function getDisplayMessage(
    message: React.ReactNode,
    error: unknown,
    extraErrorCodes: Record<string, React.ReactNode> | undefined,
    defaultMessage: React.ReactNode = 'Something went wrong.',
) {
    if (message) {
        return message;
    }
    if (error instanceof ApolloError) {
        return getErrorMessage(error, {defaultMessage, extraErrorCodes});
    }
    return defaultMessage;
}
