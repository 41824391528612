import * as Yup from 'yup';
import {isEmpty, get, filter} from 'lodash';

Yup.addMethod(Yup.array, 'unique', function (propertyPath, message) {
    return this.test('unique', '', function (list) {
        const errors: Yup.ValidationError[] = [];

        if (list == null) {
            return true;
        }

        list.forEach((item, index) => {
            const propertyValue = get(item, propertyPath);

            if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
                errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyPath}`,
                        message,
                    }),
                );
            }
        });

        if (!isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});

declare module 'yup' {
    interface ArraySchema<T> {
        unique(propertyPath: keyof Yup.InferType<T>, message?: any): ArraySchema<T>;
    }
}
