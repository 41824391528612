import React from 'react';
import {NetworkStatus, OperationVariables, TypedDocumentNode, useLazyQuery} from '@apollo/client';

type PollingConfig = {
    pollInterval: number;
    maxNumberOfTries?: number;
    startImmediately?: boolean;
};
const defaultPollingConfig: PollingConfig = {
    pollInterval: 2500,
    startImmediately: true,
};
export function usePollQuery<T, D>(
    document: TypedDocumentNode<T, D>,
    shouldStopPolling: (data: T) => boolean,
    pollingConfig?: Partial<PollingConfig>,
    variables?: OperationVariables,
) {
    const finalPollingConfig = {
        ...defaultPollingConfig,
        ...pollingConfig,
    };
    const {maxNumberOfTries} = finalPollingConfig;
    const [startPoll, result] = useLazyQuery(document, {
        pollInterval: finalPollingConfig.pollInterval,
        notifyOnNetworkStatusChange: true,
        variables: variables,
        fetchPolicy: 'network-only',
    });
    const [attempts, setAttempts] = React.useState(0);
    const [finished, setFinished] = React.useState(false);
    const [stopPollingFlag, setStopPollingFlag] = React.useState(false);

    const {data, error, stopPolling: resultStopPolling} = result;

    const stopPolling = React.useCallback(() => {
        setStopPollingFlag(true);
    }, [stopPollingFlag]);

    React.useEffect(() => {
        if (stopPollingFlag && resultStopPolling != null) {
            resultStopPolling();
        }
    }, [resultStopPolling, stopPollingFlag]);

    React.useEffect(() => {
        if (result.networkStatus === NetworkStatus.ready && result.data != null) {
            setAttempts((value) => value + 1);
        }
    }, [result, setAttempts]);

    React.useEffect(() => {
        if (maxNumberOfTries != null && maxNumberOfTries < attempts) {
            stopPolling();
            setFinished(true);
        }
    }, [maxNumberOfTries, attempts, stopPolling, setFinished]);

    React.useEffect(() => {
        if (data && shouldStopPolling(data)) {
            stopPolling();
            setFinished(true);
        }
    }, [data, stopPolling, setFinished]);

    React.useEffect(() => {
        if (error) {
            stopPolling();
            setFinished(true);
        }
    }, [error, stopPolling, setFinished]);

    React.useEffect(() => {
        if (finalPollingConfig.startImmediately) {
            startPoll({variables});
        }
    }, []);

    return {
        loading: result.loading,
        error: result.error,
        data: result.data,
        called: result.called,
        finished,
        startPolling: (pollVars: OperationVariables) => {
            if (finalPollingConfig.startImmediately) {
                throw new Error('polling was configured to start immediately');
            }
            setFinished(false);
            startPoll({variables: pollVars});
        },
    };
}
