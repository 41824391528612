import React from 'react';
import {useField} from 'formik';
import {SchemaOf} from 'yup';

import {ValidationSchemaContext} from '../form';

/**
 * Wraps a carbon form component to be compatible with formik.
 *
 * Wrapped components come with validation for `required` fields out-of-the-box.
 * The default error message can be overridden by supplying a `validationSchema` to the `Form` component, its message will take precedence.
 */
export function withFormik<T, P>(name: string, Element: React.ComponentType<P>, defaultValue: T) {
    /**
     * The adapter integrates carbon components with Formik.
     * Carbon does not come with a form controller, so we'll use Formik for that.
     *
     * With this adapter, we can render a carbon form element with Formik as the controller.
     * The adapter:
     * - Makes sure the carbon component is rendered in uncontrolled mode, such that Formik can control the form.
     * - Proxies errors from Formik to the component
     * - Marks the field as required based on the validationSchema
     */
    const component = function formikCarbonAdapter(props: P & WrapperProps) {
        const [field, meta] = useField<T>({name: props.name});
        const validationSchema = React.useContext(ValidationSchemaContext);

        const extraProps = {
            error: ('error' in props && props.error) || meta.error,
            // We set the required prop based on the validation schema.
            // If a value is marked as required there, we require it here, too, for proper styling.
            required: validationSchema && isRequired(validationSchema, props.name),
        };

        // Note that we MUST have a default value: if not, then the component will be rendered in controlled mode,
        // while we need uncontrolled mode such that Formik can control our values.
        return (
            <Element {...props} {...field} {...extraProps} value={field.value ?? defaultValue} />
        );
    };
    component.displayName = name;
    return component;
}

function isRequired(validationSchema: SchemaOf<any>, field: string): boolean {
    // We specifically expect an object scheme here, but aren't currently enforcing this.
    // FIXME: ideally, we should update our types to follow suite
    const fieldSchema =
        validationSchema && 'fields' in validationSchema ? validationSchema.fields[field] : null;

    // Similar as per previous comment, we're specifically expecting non-lazy schemes here
    return !!fieldSchema && 'spec' in fieldSchema && fieldSchema.spec.presence === 'required';
}

type WrapperProps = {
    name: string;
    /**
     * Use a validationSchema to mark a field as required.
     */
    required?: never;
};
