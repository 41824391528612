import React from 'react';
import classnames from 'classnames';
import {useTextField} from '@react-aria/textfield';
import {useButton} from '@react-aria/button';

import {FormControlContext} from './form-control';
import {SearchIcon, ShowPasswordIcon, HidePasswordIcon} from './icons';
import {DetailText} from './detail-text';
import {outlineColorClassnames} from './utils';

export type InputProps = {
    name: string;
    placeholder?: string;
    label?: string;
    showLabel?: boolean;
    type?: string;
    value: string;
    maxLength?: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isInvalid?: boolean;
    icon?: React.ReactNode;
    onPressIcon?: (value: string) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onBlur?: (e: React.FocusEvent) => void;
    rightLabel?: React.ReactNode;
    disableEnter?: boolean;
    readOnly?: boolean;
};
export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
    {
        value,
        name,
        type = 'input',
        placeholder,
        label,
        showLabel = true,
        maxLength,
        onChange,
        isInvalid,
        onPressIcon,
        icon,
        onFocus,
        onBlur,
        rightLabel,
        disableEnter = false,
        readOnly = false,
    },
    ref,
) {
    const inputRef =
        (ref as React.RefObject<HTMLInputElement>) ?? React.useRef<HTMLInputElement>(null);
    const iconRef = React.useRef<HTMLDivElement>(null);
    const formControlCtx = React.useContext(FormControlContext);
    const {buttonProps} = useButton(
        {
            onPress: onPressIcon == null ? undefined : () => onPressIcon(value),
        },
        iconRef,
    );
    const valid = !formControlCtx.isInvalid && !isInvalid;
    const labelValue = label ?? placeholder ?? name;
    const {labelProps, inputProps} = useTextField(
        {
            inputElementType: 'input',
            name,
            placeholder,
            type,
            maxLength,
            value,
            validationState: valid ? 'valid' : 'invalid',
            'aria-label': labelValue,
            onKeyDown: (e) => {
                if (e.keyCode === 13 && disableEnter) {
                    return e.preventDefault();
                }
            },
        },
        inputRef,
    );
    let finalIcon = icon;
    if (icon == null && type === 'search') {
        finalIcon = (
            <div className="h-6 w-6">
                <SearchIcon />
            </div>
        );
    }
    return (
        <div className="relative w-full flex items-center">
            {showLabel != null && (
                <label
                    {...labelProps}
                    className={classnames(
                        'absolute bg-white top-0 left-3 px-1 transition-opacity leading-none',
                        {
                            'sr-only opacity-0': !showLabel || value === '',
                            'opacity-100': showLabel && value !== '',
                        },
                    )}
                >
                    <DetailText size="small">{labelValue}</DetailText>
                </label>
            )}
            <input
                {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
                ref={inputRef}
                className={classnames(
                    'font-body rounded-xl text-100 font-400 leading-500 border py-2 px-4 w-full transition-colors focus:outline-none focus:ring-1 placeholder-yin-90',
                    outlineColorClassnames('input'),
                    formControlCtx.isInvalid ||
                        (isInvalid &&
                            `${outlineColorClassnames('error')} focus:ring-semantic-negative-500`),
                    {
                        'mt-2': showLabel,
                        'focus:border-2': !formControlCtx.isInvalid && !isInvalid,
                        'pr-8': icon != null,
                    },
                )}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                readOnly={readOnly}
            />
            {rightLabel != null && (
                <div className="ml-5">
                    <DetailText>{rightLabel}</DetailText>
                </div>
            )}
            {finalIcon && (
                <div
                    ref={iconRef}
                    {...buttonProps}
                    className={classnames(
                        'absolute right-2 bottom-0 flex items-center justify-center cursor-pointer appearance-none',
                        {
                            'top-2': showLabel,
                            'top-0': !showLabel,
                        },
                    )}
                >
                    {finalIcon}
                </div>
            )}
        </div>
    );
});

export function PasswordInput(props: Omit<InputProps, 'type' | 'icon' | 'onPressIcon'>) {
    const [inputType, setInputType] = React.useState('password');
    return (
        <Input
            {...props}
            type={inputType}
            icon={
                <div className="h-6 w-6 text-action-minor-500">
                    {inputType === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </div>
            }
            onPressIcon={() =>
                setInputType((value) => (value === 'password' ? 'text' : 'password'))
            }
        />
    );
}
