import React from 'react';
import {Link} from 'react-router-dom';

import {LinkText} from '@sphericsio/design-system';

export const ERROR_CODES: Record<string, string | React.ReactNode | null> = {
    NO_SUCH_USER: 'User with this email address does not exist.',
    INCORRECT_CREDENTIALS: 'Your email or password is incorrect.',
    INVALID_SIGNUP_PROVIDER: 'We do not allow users to sign up with this provider',
    INCORRECT_ROLE: 'You do not have access to this resource.',
    EMAIL_NOT_VERIFIED: "You haven't verified your email yet. Please click the link we sent you.",
    USER_DISABLED: 'Your user has been disabled. Please get in touch if it has been done in error.',
    USER_USES_ACCOUNTING_PLATFORM_LOGIN: (
        <>
            You already have an account with an accounting platform,{' '}
            <Link to="/login/accounting">
                <LinkText>click here to login</LinkText>
            </Link>
        </>
    ),
    USER_EXISTS: (
        <>
            You already have an account,{' '}
            <Link to="/login">
                <LinkText>click here to login</LinkText>
            </Link>
        </>
    ),
    TERMS_NOT_AGREED: 'You need to accept our terms & conditions to proceed.',
    CANNOT_CHANGE_TENANT: (
        <>
            You have already chosen your account to sync.{' '}
            <Link to="/dashboard">
                <LinkText>Click here to go to your dashboard</LinkText>
            </Link>
        </>
    ),
    INVALID_INVITE:
        'Your invite code has expired or has already been used. Please contact support for a new invite.',
    INVITE_CODE_EXPIRED: 'Your invite code has expired. Please contact support to request another.',
    INVITE_CODE_ALREADY_USED: 'Your invite code has already been used.',
    INVITE_CODE_UNKNOWN:
        'Your invite code was not recognised. Please contact support to request another.',

    UNKNOWN_ERROR: null,
    NETWORK_ERROR: null,

    // Not intended to be used in prod, but useful for testing purposes.
    __TEST_ERROR__: 'An error occurred',
};
