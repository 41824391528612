import React, {
    useLayoutEffect,
    useState,
    createContext,
    PropsWithChildren,
    useEffect,
    useRef,
    Fragment,
} from 'react';
import {Transition, Dialog} from '@headlessui/react';

import {Panel} from './panel';
import {Background} from './utils';
import {CloseIcon} from './icons';

export const ModalContext = createContext({
    showing: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setShowing: (_showing: boolean) => {
        //empty
    },
});

export function ModalProvider({children}: PropsWithChildren<unknown>) {
    const [showing, setShowing] = useState(false);
    return <ModalContext.Provider value={{showing, setShowing}}>{children}</ModalContext.Provider>;
}

type ModalProps = {
    onClose: () => void;
    isOpen: boolean;
    panelBg?: Background;
    children: React.ReactNode;
    testId?: string;
};
export function Modal({children, onClose, isOpen, panelBg, testId}: ModalProps) {
    const [actuallyOpen, setActuallyOpen] = React.useState(false);
    const timeoutId = useRef<any>();
    const scrollbarMeasureRef = useRef<HTMLDivElement | null>();
    const closeRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (!isOpen) {
            clearTimeout(timeoutId.current);
            document.body.classList.remove('overflow-y-hidden');
            document.body.style.paddingRight = '0';
        }
        setActuallyOpen(isOpen);
    }, [isOpen, setActuallyOpen]);
    useLayoutEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-y-hidden');
            if (scrollbarMeasureRef.current) {
                const el = scrollbarMeasureRef.current;
                const scrollbarWidth = el.offsetWidth - el.clientWidth;
                document.body.style.paddingRight = `${scrollbarWidth}px`;
            }
        }
    }, [isOpen]);

    const close = () => {
        setActuallyOpen(false);
        timeoutId.current = setTimeout(() => {
            onClose();
        }, 200);
    };

    return (
        <Transition appear show={actuallyOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed top-0 left-0 right-0 bottom-0 z-10"
                onClose={close}
                initialFocus={closeRef}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <Panel bg={panelBg}>
                                    <div
                                        data-testid={testId}
                                        role="dialog"
                                        aria-modal="true"
                                        aria-labelledby="modal-headline"
                                        className="relative"
                                    >
                                        <div
                                            data-testid="closeIcon"
                                            className="text-yin-90 h-5 w-5 cursor-pointer absolute top-0 right-0"
                                            onClick={() => close()}
                                            ref={(ref) => (closeRef.current = ref)}
                                        >
                                            <CloseIcon />
                                        </div>
                                        <div className="py-3 pl-3 pr-10">{children}</div>
                                    </div>
                                </Panel>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
