import {makeVar, useReactiveVar} from '@apollo/client';

export function buildLoginRedirect(ignoredPaths: string[]) {
    const redirectPath = makeVar<string | undefined>(undefined);

    return {
        useRedirectPath: () => useReactiveVar(redirectPath),
        storeRedirectPath: () => {
            let path = window.location.pathname;
            if (ignoredPaths.includes(path)) {
                path = '/';
            }

            redirectPath(path);
        },
        clearRedirectPath: () => {
            redirectPath(undefined);
        },
    };
}
