import React from 'react';

import {intercomTargetProps} from './intercom-target';
import {Panel} from './panel';

type ListItemProps = {
    children: React.ReactNode;
    intercomTarget?: string;
};
export function ListItem({intercomTarget, children}: ListItemProps) {
    return (
        <li {...intercomTargetProps(intercomTarget)}>
            <Panel border>{children}</Panel>
        </li>
    );
}

type ListProps = {
    children: React.ReactNode;
};
export function List({children}: ListProps) {
    return <ul className="space-y-2">{children}</ul>;
}
