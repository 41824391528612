import React from 'react';
import classnames from 'classnames';

import {bgColorClassnames, outlineColorClassnames, textColorClassnames} from './utils';
import {DetailText} from './detail-text';
import {CircleCrossFilled, InfoIcon} from './icons';
import {VStack} from './stack';
import {Button} from './button';
import {intercomTargetProps} from './intercom-target';
import {Heading5} from './heading-5';

type AlertType = 'info' | 'error';

export type AlertProps = {
    type?: AlertType;
    title?: string;
    subtitle?: string;
    size?: 'xs' | 'large';
    onPress?: () => void;
    buttonLabel?: string;
    intercomTarget?: string;
};

export function Alert({
    type = 'info',
    size = 'large',
    buttonLabel = 'Complete now',
    title,
    subtitle,
    onPress,
    intercomTarget,
}: AlertProps) {
    return (
        <div
            className={classnames(
                'flex flex-row border rounded-lg',
                bgColorClassnames(type),
                outlineColorClassnames(type),
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            <div
                className={classnames('relative', {
                    'w-16': size === 'large',
                    'w-12 pl-4': size === 'xs',
                })}
            >
                <div
                    className={classnames('w-6 h-6 absolute', {
                        'top-1/4 left-1/2 -ml-3': size === 'large',
                        'top-2 left-1/2 -ml-3': size === 'xs',
                    })}
                >
                    {type === 'error' ? (
                        <div className={textColorClassnames('error')}>
                            <CircleCrossFilled />
                        </div>
                    ) : (
                        <InfoIcon />
                    )}
                </div>
            </div>
            <div
                className={classnames(
                    'bg-white rounded-r-lg w-full flex flex-row',
                    textColorClassnames('black'),
                    {
                        'py-6 pl-6': size === 'large',
                        'py-3 px-4': size === 'xs',
                        'items-center justify-between': !!onPress,
                        'items-start': !onPress,
                    },
                )}
            >
                <VStack condensed align="start">
                    {title && size === 'xs' && <DetailText bold>{title}</DetailText>}
                    {title && size !== 'xs' && <Heading5>{title}</Heading5>}
                    {subtitle && (
                        <div className="text-left pr-2">
                            <DetailText>{subtitle}</DetailText>
                        </div>
                    )}
                </VStack>
                {onPress && (
                    <div className="pr-10">
                        <Button small onPress={onPress}>
                            {buttonLabel}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
