import React from 'react';
import {TypedDocumentNode, useQuery, FetchPolicy} from '@apollo/client';

import {Button, ChevronUpIcon, ChevronDownIcon} from '@sphericsio/design-system';

declare module 'react' {
    function forwardRef<T, P = Record<string, unknown>>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type TableTextAlignment = 'left' | 'center' | 'right';
type TextAlignProps = {
    textAlign?: TableTextAlignment;
};
type WidthProps = {
    width?: string;
};
type SkeletonProps = {
    skeleton?: boolean;
};
type CondensedProps = {
    condensed?: boolean;
};
export type Elements = {
    Table: React.ElementType<JSX.IntrinsicElements['table']>;
    THead: React.ElementType<JSX.IntrinsicElements['thead']>;
    TBody: React.ElementType<JSX.IntrinsicElements['tbody']>;
    THeader: React.ElementType<JSX.IntrinsicElements['th'] & TextAlignProps & WidthProps>;
    TRow: React.ElementType<JSX.IntrinsicElements['tr']>;
    TData: React.ElementType<
        JSX.IntrinsicElements['td'] & TextAlignProps & WidthProps & SkeletonProps & CondensedProps
    >;
};

export type PageInfo = {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
};

type RelayConnectionResult<T> = {
    pageInfo: Partial<PageInfo>;
    edges: {node: T}[];
};

export type SortDirection = 'asc' | 'desc';

export type RelayOrderVariables<T extends string> = {
    orderBy: T;
    orderDirection: SortDirection;
};

export type RelayConnectionVariables = {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
};

export type RelayConnectionQuery<R, V extends RelayConnectionVariables, T> = {
    document: TypedDocumentNode<R, V>;
    connection: (result: R) => RelayConnectionResult<T>;
    defaultVariables?: Partial<V>;
    fetchPolicy?: FetchPolicy;
};

export function relayConnectionQuery<R, V extends RelayConnectionVariables, T>(
    document: TypedDocumentNode<R, V>,
    connection: (result: R) => RelayConnectionResult<T>,
    defaultVariables?: Partial<V>,
    fetchPolicy?: FetchPolicy,
): RelayConnectionQuery<R, V, T> {
    return {
        document,
        connection,
        defaultVariables,
        fetchPolicy,
    };
}

type ColumnRowConfig = {
    label: React.ReactNode;
    align?: TableTextAlignment;
    width?: string;
};

type DataColumnRowConfig<T, K extends keyof T> = ColumnRowConfig & {
    key: K;
    renderValue?: (data: T[K], row: T) => React.ReactNode;
};

type RenderColumnRowConfig<T> = ColumnRowConfig & {
    key?: undefined;
    render: (row: T) => React.ReactNode;
};

type ColumnConfig<T> = {
    [K in keyof T]: DataColumnRowConfig<T, K> | RenderColumnRowConfig<T>;
}[keyof T];

export type DataListProps<R, V extends RelayConnectionVariables, T> = {
    queryConfig: RelayConnectionQuery<R, V, T>;
    idField?: keyof T;
    onClickRow?: (row: T) => void;
    pageSize?: number;
};

type DataTableProps<
    R,
    V extends RelayOrderVariables<string> & RelayConnectionVariables,
    T,
> = DataListProps<R, V, T> & {
    columns: ColumnConfig<T>[];
    sortableFields: V['orderBy'][];
    initialSortDirection?: SortDirection;
    header?: boolean;
    condensed?: boolean;
};

export function tableProps<R, V extends RelayOrderVariables<string> & RelayConnectionVariables, T>(
    config: DataTableProps<R, V, T>,
) {
    return config;
}

function isDataColumn<T>(
    _obj: T,
    config: ColumnConfig<T>,
): config is DataColumnRowConfig<T, keyof T> {
    return (config as DataColumnRowConfig<T, keyof T>).key != null;
}

class RowData<ROW> {
    constructor(public row: ROW) {}

    rowKey(idField?: keyof ROW) {
        if (idField == null) {
            idField = 'id' as keyof ROW;
        }

        return `${this.row[idField]}`;
    }

    renderColumnContent(columnConfig: ColumnConfig<ROW>): React.ReactNode {
        if (isDataColumn(this.row, columnConfig)) {
            if (columnConfig.renderValue == null) {
                // FIXME: this is not typesafe. We don't actually know if this.row[columnConfig.key] is a react node,
                //        but it's not feasible to type this properly.
                return this.row[columnConfig.key] as React.ReactNode;
            } else {
                return columnConfig.renderValue(this.row[columnConfig.key], this.row);
            }
        } else {
            return (columnConfig as RenderColumnRowConfig<ROW>).render(this.row);
        }
    }
}

class TableData<ROW> {
    rows: RowData<ROW>[];
    constructor(data?: RelayConnectionResult<ROW>) {
        if (data == null) {
            this.rows = [];
        } else {
            this.rows = data.edges.map((edge) => new RowData(edge.node));
        }
    }
}

export function useDataTable<QUERY_RESULT, VARIABLES extends RelayConnectionVariables, ROW>(
    queryConfig: RelayConnectionQuery<QUERY_RESULT, VARIABLES, ROW>,
    pageSize: number,
    extraVariables: Partial<VARIABLES> = {},
) {
    const [resultsSize, setResultsSize] = React.useState<number>(pageSize);

    const requiredPageSize = React.useMemo(() => {
        return resultsSize > pageSize ? resultsSize : pageSize;
    }, [resultsSize, pageSize]);

    const variables = {
        ...queryConfig.defaultVariables,
        ...extraVariables,
        first: pageSize,
    } as VARIABLES;

    const {loading, error, data, fetchMore, refetch} = useQuery(queryConfig.document, {
        variables,
        fetchPolicy: queryConfig.fetchPolicy,
        notifyOnNetworkStatusChange: true,
    });

    const onLoadNext = (endCursor: string) => {
        fetchMore({variables: {after: endCursor}});
        setResultsSize(resultsSize + pageSize);
    };
    const onLoadPrevious = (startCursor: string) => {
        fetchMore({variables: {last: pageSize, first: undefined, before: startCursor}});
        setResultsSize(resultsSize - pageSize);
    };

    const onRefetch = () => {
        if (requiredPageSize > pageSize) {
            const refetchVariables = {...variables, first: requiredPageSize};
            refetch(refetchVariables);
        } else {
            refetch(variables);
        }
    };

    const connection = data == null ? undefined : queryConfig.connection(data);
    const tableData = new TableData(connection);
    const pageInfo = connection?.pageInfo;

    return {loading, error, onLoadNext, onLoadPrevious, onRefetch, tableData, pageInfo};
}

export function useSortableDataTable<
    QUERY_RESULT,
    VARIABLES extends RelayConnectionVariables & RelayOrderVariables<string>,
    ROW,
>(
    queryConfig: RelayConnectionQuery<QUERY_RESULT, VARIABLES, ROW>,
    pageSize: number,
    sortableFields: VARIABLES['orderBy'][],
    initialSortDirection: SortDirection = 'asc',
) {
    const [orderBy, setOrderBy] = React.useState<VARIABLES['orderBy']>(sortableFields[0]);
    const [orderDirection, setOrderDirection] = React.useState<SortDirection>(initialSortDirection);
    const dataTable = useDataTable(queryConfig, pageSize, {
        orderBy,
        orderDirection,
    } as Partial<VARIABLES>);

    function onChangeSort(column: VARIABLES['orderBy']) {
        if (column === orderBy) {
            setOrderDirection((value) => {
                return value === 'asc' ? 'desc' : 'asc';
            });
        } else {
            setOrderBy(column);
            setOrderDirection(initialSortDirection);
        }
    }

    return {...dataTable, orderDirection, orderBy, onChangeSort};
}

export type DataTableRef = {
    refreshData: () => void;
};

export function buildDataTableComponent(E: Elements) {
    type MoreDataRowProps = {
        hasMoreRows?: boolean;
        cursor?: string | null;
        onClick?: (cursor: string) => void;
        colSpan: number;
        children: React.ReactNode;
        loading: boolean;
    };
    function MoreDataRow({
        hasMoreRows,
        cursor,
        onClick,
        colSpan,
        children,
        loading,
    }: MoreDataRowProps) {
        if (!hasMoreRows || cursor == null || onClick == null) {
            return null;
        }

        return (
            <E.TRow>
                <E.TData colSpan={colSpan} className="pt-5">
                    <Button
                        onPress={() => onClick(cursor)}
                        isLoading={loading}
                        isDisabled={loading}
                    >
                        {children}
                    </Button>
                </E.TData>
            </E.TRow>
        );
    }
    function DataTableWithRef<
        QUERY_RESULT,
        VARIABLES extends RelayConnectionVariables & RelayOrderVariables<string>,
        ROW,
    >(
        props: DataTableProps<QUERY_RESULT, VARIABLES, ROW>,
        ref: React.Ref<DataTableRef>,
    ): React.ReactElement {
        const {
            queryConfig,
            columns,
            idField = 'id',
            pageSize = 10,
            sortableFields,
            onClickRow,
            initialSortDirection = 'asc',
            header = true,
            condensed = false,
        } = props;

        const {
            loading,
            error,
            tableData,
            pageInfo,
            onLoadNext,
            onLoadPrevious,
            orderBy,
            orderDirection,
            onChangeSort,
            onRefetch,
        } = useSortableDataTable(queryConfig, pageSize, sortableFields, initialSortDirection);

        React.useImperativeHandle(ref, () => ({
            refreshData: onRefetch,
        }));

        return (
            <E.Table>
                {header && (
                    <E.THead>
                        <E.TRow>
                            {columns.map((col, index) => {
                                const key = col.key as string;
                                const isSortable =
                                    col.key != null &&
                                    props.sortableFields.includes(key as VARIABLES['orderBy']);
                                return (
                                    <E.THeader
                                        textAlign={col.align || 'left'}
                                        width={col.width}
                                        key={col.key == null ? `${index}` : `${String(col.key)}`}
                                        style={{cursor: isSortable ? 'pointer' : 'not-allowed'}}
                                        onClick={() =>
                                            isSortable && onChangeSort(key as VARIABLES['orderBy'])
                                        }
                                    >
                                        <div className="flex items-center space-x-2">
                                            <span>{col.label}</span>
                                            {key === orderBy && (
                                                <div className="w-4 h-4">
                                                    {orderDirection === 'asc' ? (
                                                        <ChevronUpIcon />
                                                    ) : (
                                                        <ChevronDownIcon />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </E.THeader>
                                );
                            })}
                        </E.TRow>
                    </E.THead>
                )}
                <E.TBody>
                    {loading && (
                        <E.TRow>
                            {columns.map((col, colndex) => (
                                <E.TData
                                    condensed={condensed}
                                    key={col.key ? (col.key as string) : `${colndex}`}
                                    textAlign={col.align}
                                    width={col.width}
                                    skeleton={true}
                                >
                                    Loading...
                                </E.TData>
                            ))}
                        </E.TRow>
                    )}
                    {error && (
                        <E.TRow>
                            <E.TData colSpan={columns.length}>Error...</E.TData>
                        </E.TRow>
                    )}
                    <MoreDataRow
                        hasMoreRows={pageInfo?.hasPreviousPage}
                        cursor={pageInfo?.startCursor}
                        onClick={onLoadPrevious}
                        colSpan={columns.length}
                        loading={loading}
                    >
                        Load Previous Rows
                    </MoreDataRow>
                    {tableData.rows.map((row, index) => (
                        <E.TRow
                            key={row.rowKey(idField as keyof ROW)}
                            style={{cursor: onClickRow == null ? 'default' : 'pointer'}}
                            onClick={onClickRow == null ? undefined : () => onClickRow(row.row)}
                        >
                            {...props.columns.map((col, colIndex) => (
                                <E.TData
                                    key={
                                        col.key == null
                                            ? `${index}:${colIndex}`
                                            : `${String(col.key)}`
                                    }
                                    textAlign={col.align || 'left'}
                                    width={col.width}
                                    condensed={condensed}
                                >
                                    {row.renderColumnContent(col)}
                                </E.TData>
                            ))}
                        </E.TRow>
                    ))}
                    {!loading && tableData.rows.length === 0 && (
                        <E.TRow>
                            <E.TData colSpan={columns.length}>No data.</E.TData>
                        </E.TRow>
                    )}
                    <MoreDataRow
                        hasMoreRows={pageInfo?.hasNextPage}
                        cursor={pageInfo?.endCursor}
                        onClick={onLoadNext}
                        colSpan={columns.length}
                        loading={loading}
                    >
                        Load Next Rows
                    </MoreDataRow>
                </E.TBody>
            </E.Table>
        );
    }

    return React.forwardRef(DataTableWithRef);
}
