import React from 'react';
import {useFormikContext} from 'formik';

import {
    Button as DesignSystemButton,
    ButtonProps,
    TextButton as DesignSystemTextButton,
} from '@sphericsio/design-system';

export function Button(props: Omit<ButtonProps, 'isDisabled'>) {
    const {isValid} = useFormikContext();
    return <DesignSystemButton {...props} isDisabled={!isValid} />;
}

export function TextButton(props: Omit<ButtonProps, 'isDisabled'>) {
    const {isValid} = useFormikContext();
    return <DesignSystemTextButton {...props} isDisabled={!isValid} />;
}
