import React from 'react';
import classnames from 'classnames';

type SubHeadingProps = {
    children: React.ReactNode;
    uppercase?: boolean;
};
export function SubHeading({children, uppercase}: SubHeadingProps) {
    return (
        <span
            className={classnames('font-body text-sm tracking-widest', {
                uppercase,
            })}
        >
            {children}
        </span>
    );
}
