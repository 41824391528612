import React from 'react';
import classnames from 'classnames';

import {Text, textColorClassnames} from './utils';

type DetailTextProps = {
    children: React.ReactNode;
    colour?: Text;
    inherit?: boolean;
};
export function SegmentSubheader({children, colour = 'black', inherit}: DetailTextProps) {
    return (
        <div
            className={classnames(
                'font-heading text-200 font-500 leading-500',
                !inherit && textColorClassnames(colour),
            )}
        >
            {children}
        </div>
    );
}
