import React from 'react';
import classnames from 'classnames';
import {useLink as ariaUseLink, AriaLinkOptions} from '@react-aria/link';

import {textColorClassnames} from './utils';

export type PressEvent = Parameters<Required<AriaLinkOptions>['onPress']>[0];
export type OnPressHandler = (e: PressEvent, href?: string) => void;
type UseLinkProps = {
    href?: string;
    onPress?: OnPressHandler;
    openInNewWindow?: boolean;
};

export function useLink({onPress, href, openInNewWindow = false}: UseLinkProps) {
    const anchorRef = React.useRef<HTMLAnchorElement>(null);
    const {
        linkProps: {onClick, ...linkProps},
    } = ariaUseLink(
        {
            onPress: (e) => {
                if (onPress != null) {
                    onPress(e, href);
                }
            },
        },
        anchorRef,
    );

    return {
        ...linkProps,
        ref: anchorRef,
        href,
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (onClick == null) {
                return;
            }

            if (onPress != null) {
                e.preventDefault();
                return onClick(e);
            }
        },
        ...(openInNewWindow ? {target: '_blank', rel: 'noopener noreferrer'} : {}),
    };
}

export type LinkProps = {
    href: string;
    active?: boolean;
    children: React.ReactNode;
    onPress?: (e: PressEvent, href: string) => void;
    openInNewWindow?: boolean;
    uppercase?: boolean;
    invert?: boolean;
};
export function Link(props: LinkProps) {
    const {children, href, active, onPress, openInNewWindow, uppercase = true, invert} = props;
    const linkProps = useLink({
        href,
        onPress: onPress as OnPressHandler,
        openInNewWindow,
    });
    return (
        <a
            {...linkProps}
            className={classnames(
                'font-heading',
                active && textColorClassnames('logo'),
                !active && !invert && textColorClassnames('secondary'),
                !active && invert && textColorClassnames('white'),
                {
                    uppercase: uppercase,
                },
            )}
        >
            {children}
        </a>
    );
}
