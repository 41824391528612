import React, {useEffect, useState} from 'react';
import {Formik, useFormikContext, FormikContextType, FormikValues} from 'formik';
import classnames from 'classnames';
import {SchemaOf} from 'yup';

// Unfortunately, Formik doesn't expose the ValidationSchema on its own context.
// Hence, we need to pass it down ourselves.
export const ValidationSchemaContext = React.createContext<undefined | SchemaOf<any>>(undefined);

type FormProps<T> = {
    initialValues: T;
    onSubmit: (values: T) => void;
    onChange?: (values: T, prev: T) => void;
    validationSchema?: SchemaOf<any>;
    fullWidth?: boolean;
    children: React.ReactNode;
    ctx?: FormCtx<T>;
    testId?: string;
};
export function Form<T extends FormikValues>({
    initialValues,
    onSubmit,
    validationSchema,
    fullWidth = false,
    children,
    onChange,
    ctx,
    testId = 'form',
}: FormProps<T>) {
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);

    // Formik passes additional arguments to onSubmit, that we don't want to pass along.
    // So we create a wrapper here that simply discards those values.
    const formikOnSubmit = React.useCallback((values: T) => onSubmit(values), [onSubmit]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={formikOnSubmit}
            validateOnChange={hasBeenSubmitted}
            validateOnBlur={hasBeenSubmitted}
            validationSchema={validationSchema}
        >
            {(props) => (
                <>
                    <FormObserver<T> onChange={onChange} ctx={ctx} />
                    <form
                        data-testid={testId}
                        noValidate={true}
                        className={classnames('flex-1', {'w-full': fullWidth})}
                        onSubmit={(e) => {
                            if (!hasBeenSubmitted) {
                                setHasBeenSubmitted(true);
                            }
                            props.handleSubmit(e);
                        }}
                    >
                        <ValidationSchemaContext.Provider value={validationSchema}>
                            {children}
                        </ValidationSchemaContext.Provider>
                    </form>
                </>
            )}
        </Formik>
    );
}

type FormObserverProps<T> = {
    onChange?: (values: T, prev: T) => void;
    ctx?: React.MutableRefObject<FormikContextType<T>>;
};

function FormObserver<T>(props: FormObserverProps<T>) {
    const ctx = useFormikContext<T>();
    const [prev, setPrev] = useState(ctx.values);

    if (props.ctx) {
        props.ctx.current = ctx;
    }

    useEffect(() => {
        props.onChange && props.onChange(ctx.values, prev);
        setPrev(ctx.values);
    }, [ctx.values]);

    return null;
}

export type FormCtx<T> = React.MutableRefObject<FormikContextType<T>>;
export function formCtx<T>(): FormCtx<T> {
    return React.useRef() as FormCtx<T>;
}
