import React from 'react';
import classnames from 'classnames';

type GridProps = {
    children: React.ReactNode;
    columns: 1 | 2 | 3 | 4 | 5; //supported column configs, so we can work with purgecss
};
export function Grid({children, columns}: GridProps) {
    return (
        <div
            className={classnames('grid gap-5', {
                'grid-cols-1': columns === 1,
                'grid-cols-2': columns === 2,
                'grid-cols-3': columns === 3,
                'grid-cols-4': columns === 4,
                'grid-cols-5': columns === 5,
            })}
        >
            {children}
        </div>
    );
}
