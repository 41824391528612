import React from 'react';
import {useField} from 'formik';

import {FormControl, FormErrorMessage, HStack, Pill} from '@sphericsio/design-system';

type YesNoButtonProps = {
    name: string;
    yesLabel?: React.ReactNode;
    noLabel?: React.ReactNode;
};
export function YesNoButton({name, yesLabel = 'Yes', noLabel = 'No'}: YesNoButtonProps) {
    const [field, meta, helpers] = useField<string>({name});
    return (
        <FormControl isInvalid={meta.error != null}>
            <HStack>
                <Pill isSelected={field.value === 'yes'} onChange={() => helpers.setValue('yes')}>
                    {yesLabel}
                </Pill>
                <Pill isSelected={field.value === 'no'} onChange={() => helpers.setValue('no')}>
                    {noLabel}
                </Pill>
            </HStack>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}
