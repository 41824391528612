/* eslint-disable @typescript-eslint/no-var-requires */
const tokens = require('@sage/design-tokens/js/base/common');

/**
 * This finds all the `spacingXXX` tokens in the design-tokens and generates tailwind styles for it.
 * E.g. `spacing100` -> `carbon-100: var(--spacing100)`, which can then be used as e.g. `mt-carbon-100`
 */
const spacings = Object.fromEntries(
    Object.keys(tokens)
        .filter((key) => key.startsWith('spacing'))
        .map((key) => [`carbon-${key.substring('spacing'.length)}`, `var(--${key})`]),
);

module.exports = {
    theme: {
        fontFamily: {
            body: ['"Sage UI Regular"', 'sans-serif'],
            heading: ['"Sage UI Medium"', 'sans-serif'],
            bold: ['"Sage UI Bold"', 'sans-serif'],
        },
        extend: {
            spacing: spacings,
            boxShadow: {
                10: 'var(--boxShadow010)',
                50: 'var(--boxShadow050)',
                75: 'var(--boxShadow075)',
                90: 'var(--boxShadow090)',
                91: 'var(--boxShadow091)',
                100: 'var(--boxShadow100)',
                150: 'var(--boxShadow150)',
                200: 'var(--boxShadow200)',
                300: 'var(--boxShadow300)',
                400: 'var(--boxShadow400)',
            },
            fontSize: {
                0: '0',
                10: 'var(--fontSizes010)',
                12: 'var(--fontSizes025)',
                50: 'var(--fontSizes050)',
                100: 'var(--fontSizes100)',
                200: 'var(--fontSizes200)',
                300: 'var(--fontSizes300)',
                400: 'var(--fontSizes400)',
                500: 'var(--fontSizes500)',
                600: 'var(--fontSizes600)',
                700: 'var(--fontSizes700)',
                800: 'var(--fontSizes800)',
            },
            fontWeight: {
                400: 'var(--fontWeights400)',
                500: 'var(--fontWeights500)',
                600: 'var(--fontWeights600)',
            },
            lineHeight: {
                300: 'var(--lineHeights300)',
                400: 'var(--lineHeights400)',
                500: 'var(--lineHeights500)',
            },
            colors: {
                graph: {
                    scope1: '#0E74A7',
                    scope2: '#C15708',
                    scope3: '#00884A',
                    totalEmissions: '#00A65C',
                },
                logo: 'var(--colorsLogo)',
                // these values are repeated throughout so using the first available
                yin: {
                    30: 'var(--colorsUtilityYin030)',
                    55: 'var(--colorsUtilityYin055)',
                    65: 'var(--colorsUtilityYin065)',
                    90: 'var(--colorsUtilityYin090)',
                },
                yang: {
                    30: 'var(--colorsActionMajorYang030)',
                    100: 'var(--colorsActionMajorYang100)',
                },
                transparent: 'var(--colorsUtilityMajorTransparent)',
                utility: {
                    major: {
                        10: 'var(--colorsUtilityMajor010)',
                        40: 'var(--colorsUtilityMajor040)',
                        50: 'var(--colorsUtilityMajor050)',
                        75: 'var(--colorsUtilityMajor075)',
                        100: 'var(--colorsUtilityMajor100)',
                        150: 'var(--colorsUtilityMajor150)',
                        200: 'var(--colorsUtilityMajor200)',
                        300: 'var(--colorsUtilityMajor300)',
                        400: 'var(--colorsUtilityMajor400)',
                        450: 'var(--colorsUtilityMajor450)',
                        500: 'var(--colorsUtilityMajor500)',
                        800: 'var(--colorsUtilityMajor800)',
                    },
                    disabled: {
                        400: 'var(--colorsUtilityDisabled400)',
                        500: 'var(--colorsUtilityDisabled500)',
                        600: 'var(--colorsUtilityDisabled600)',
                    },
                    readOnly: {
                        400: 'var(--colorsUtilityReadOnly400)',
                        500: 'var(--colorsUtilityReadOnly500)',
                        600: 'var(--colorsUtilityReadOnly600)',
                    },
                },
                action: {
                    major: {
                        25: 'var(--colorsActionMajor025)',
                        150: 'var(--colorsActionMajor150)',
                        350: 'var(--colorsActionMajor200)',
                        450: 'var(--colorsActionMajor450)',
                        500: 'var(--colorsActionMajor500)',
                        600: 'var(--colorsActionMajor600)',
                        700: 'var(--colorsActionMajor700)',
                    },
                    minor: {
                        25: 'var(--colorsActionMinor025)',
                        50: 'var(--colorsActionMinor050)',
                        100: 'var(--colorsActionMinor100)',
                        150: 'var(--colorsActionMinor150)',
                        200: 'var(--colorsActionMinor200)',
                        250: 'var(--colorsActionMinor250)',
                        300: 'var(--colorsActionMinor300)',
                        400: 'var(--colorsActionMinor400)',
                        500: 'var(--colorsActionMinor500)',
                        550: 'var(--colorsActionMinor550)',
                        600: 'var(--colorsActionMinor600)',
                        850: 'var(--colorsActionMinor850)',
                        900: 'var(--colorsActionMinor900)',
                    },
                    disabled: {
                        400: 'var(--colorsActionDisabled400)',
                        500: 'var(--colorsActionDisabled500)',
                        600: 'var(--colorsActionDisabled600)',
                    },
                    readOnly: {
                        400: 'var(--colorsActionReadOnly400)',
                        500: 'var(--colorsActionReadOnly500)',
                        600: 'var(--colorsActionReadOnly600)',
                    },
                },
                semantic: {
                    neutral: {
                        200: 'var(--colorsSemanticNeutral200)',
                        500: 'var(--colorsSemanticNeutral500)',
                        600: 'var(--colorsSemanticNeutral600)',
                    },
                    focus: {
                        250: 'var(--colorsSemanticFocus250)',
                        500: 'var(--colorsSemanticFocus500)',
                    },
                    positive: {
                        500: 'var(--colorsSemanticPositive500)',
                        600: 'var(--colorsSemanticPositive600)',
                    },
                    negative: {
                        350: 'var(--colorsSemanticNegative350)',
                        450: 'var(--colorsSemanticNegative450)',
                        500: 'var(--colorsSemanticNegative500)',
                        600: 'var(--colorsSemanticNegative600)',
                    },
                    caution: {
                        400: 'var(--colorsSemanticCaution400)',
                        500: 'var(--colorsSemanticCaution500)',
                        600: 'var(--colorsSemanticCaution600)',
                        650: 'var(--colorsSemanticCaution650)',
                    },
                    info: {
                        150: 'var(--colorsSemanticInfo150)',
                        500: 'var(--colorsSemanticInfo500)',
                        600: 'var(--colorsSemanticInfo600)',
                    },
                },
                gray: {
                    600: 'var(--colorsGray600)',
                    700: 'var(--colorsGray700)',
                    750: 'var(--colorsGray750)',
                    850: 'var(--colorsGray850)',
                    900: 'var(--colorsGray900)',
                    1000: 'var(--colorsGray1000)',
                    0: 'var(--colorsGray000)',
                },
            },
            flex: {
                '1-px': '1 1 0px',
            },
        },
    },
};
