import React from 'react';
import classnames from 'classnames';

type DropdownListProps<T> = {
    items: T[];
    renderItem: (item: T) => React.ReactNode;
    itemKey?: (item: T) => string;
    onClickItem?: (item: T) => void;
    isDisabled?: boolean;
};
export function DropdownList<T>({
    items,
    renderItem,
    itemKey,
    onClickItem,
    isDisabled,
}: DropdownListProps<T>) {
    return (
        <div className="max-h-52 overflow-y-scroll text-left bg-white rounded-xl pr-0 border border-utility-major-100">
            <ul
                className={classnames('w-full divide-y border-utility-major-100', {
                    'opacity-100': !isDisabled,
                    'opacity-30': isDisabled,
                })}
            >
                {items.map((item, index) => (
                    <li
                        key={itemKey == null ? index : itemKey(item)}
                        className={classnames('py-2.5 pl-5 pr-5 hover:bg-utility-major-100', {
                            'cursor-pointer': !isDisabled && onClickItem != null,
                            'cursor-not-allowed': isDisabled,
                        })}
                        onClick={onClickItem == null ? undefined : () => onClickItem(item)}
                    >
                        {renderItem(item)}
                    </li>
                ))}
            </ul>
        </div>
    );
}
