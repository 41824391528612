import React from 'react';

export function DownloadIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.75 8.24951H17.625C18.1223 8.24951 18.5992 8.44706 18.9508 8.79869C19.3024 9.15032 19.5 9.62723 19.5 10.1245V19.8745C19.5 20.3718 19.3024 20.8487 18.9508 21.2003C18.5992 21.552 18.1223 21.7495 17.625 21.7495H6.375C5.87772 21.7495 5.40081 21.552 5.04917 21.2003C4.69754 20.8487 4.5 20.3718 4.5 19.8745V10.1245C4.5 9.62723 4.69754 9.15032 5.04917 8.79869C5.40081 8.44706 5.87772 8.24951 6.375 8.24951H8.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.25 12.7495L12 16.4995L15.75 12.7495"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 2.24902V15.749"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function SuccessIcon() {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5559 20.4823C16.3858 20.4823 21.1118 15.8972 21.1118 10.2412C21.1118 4.58513 16.3858 0 10.5559 0C4.72604 0 0 4.58513 0 10.2412C0 15.8972 4.72604 20.4823 10.5559 20.4823Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1387 6.3735C15.5194 6.69317 15.5688 7.26087 15.2491 7.64149L9.91049 13.9981C9.73949 14.2017 9.4872 14.3193 9.22131 14.3193C8.95541 14.3193 8.70313 14.2017 8.53212 13.9981L5.86281 10.8198C5.54314 10.4392 5.59256 9.87146 5.97318 9.55179C6.35381 9.23212 6.92151 9.28153 7.24118 9.66216L9.22131 12.0199L13.8707 6.48386C14.1904 6.10324 14.7581 6.05383 15.1387 6.3735Z"
                fill="white"
            />
        </svg>
    );
}

export function SearchIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.4083 19.7545L16.9978 15.344C18.0597 13.9304 18.6329 12.2098 18.631 10.4418C18.631 5.92589 14.9569 2.25183 10.441 2.25183C5.92504 2.25183 2.25098 5.92589 2.25098 10.4418C2.25098 14.9578 5.92504 18.6318 10.441 18.6318C12.2089 18.6338 13.9296 18.0605 15.3432 16.9987L19.7536 21.4092C19.9769 21.6087 20.2681 21.7153 20.5674 21.7069C20.8667 21.6985 21.1515 21.5759 21.3633 21.3641C21.575 21.1524 21.6977 20.8676 21.706 20.5683C21.7144 20.2689 21.6079 19.9777 21.4083 19.7545ZM4.59098 10.4418C4.59098 9.28481 4.93407 8.15377 5.57688 7.19174C6.21968 6.22972 7.13333 5.47991 8.20228 5.03713C9.27122 4.59436 10.4475 4.47851 11.5823 4.70424C12.717 4.92996 13.7594 5.48712 14.5775 6.30525C15.3957 7.12339 15.9528 8.16576 16.1786 9.30055C16.4043 10.4353 16.2884 11.6116 15.8457 12.6805C15.4029 13.7495 14.6531 14.6631 13.6911 15.3059C12.729 15.9487 11.598 16.2918 10.441 16.2918C8.89003 16.29 7.40314 15.673 6.30646 14.5763C5.20977 13.4797 4.59284 11.9928 4.59098 10.4418Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function ChevronDownIcon() {
    return (
        <svg data-testid="chevronDown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M112 184l144 144 144-144"
            />
        </svg>
    );
}

export function ChevronUpIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M112 328l144-144 144 144"
            />
        </svg>
    );
}

export function ChevronRightIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
    );
}

export function ChevronLeftIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    );
}

export function ShowPasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g fill="currentColor">
                <circle cx="256" cy="256" r="64" />
                <path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96c-42.52 0-84.33 12.15-124.27 36.11-40.73 24.43-77.63 60.12-109.68 106.07a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416c46.71 0 93.81-14.43 136.2-41.72 38.46-24.77 72.72-59.66 99.08-100.92a32.2 32.2 0 00-.1-34.76zM256 352a96 96 0 1196-96 96.11 96.11 0 01-96 96z" />
            </g>
        </svg>
    );
}

export function HidePasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g fill="currentColor">
                <path d="M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zM248 315.85l-51.79-51.79a2 2 0 00-3.39 1.69 64.11 64.11 0 0053.49 53.49 2 2 0 001.69-3.39zM264 196.15L315.87 248a2 2 0 003.4-1.69 64.13 64.13 0 00-53.55-53.55 2 2 0 00-1.72 3.39z" />
                <path d="M491 273.36a32.2 32.2 0 00-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 00-71.82 11.79 4 4 0 00-1.56 6.63l47.24 47.24a4 4 0 003.82 1.05 96 96 0 01116 116 4 4 0 001.05 3.81l67.95 68a4 4 0 005.4.24 343.81 343.81 0 0067.24-77.4zM256 352a96 96 0 01-93.3-118.63 4 4 0 00-1.05-3.81l-66.84-66.87a4 4 0 00-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0072.64-11.55 4 4 0 001.61-6.64l-47.47-47.46a4 4 0 00-3.81-1.05A96 96 0 01256 352z" />
            </g>
        </svg>
    );
}

export function CloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M368 368L144 144M368 144L144 368"
            />
        </svg>
    );
}

export function Circle() {
    return (
        <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5" cy="10.5" r="9.75" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
}

export function CircleIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="17.5"
                cy="17.5"
                r="17"
                stroke="currentColor"
                strokeOpacity="0.4"
                strokeDasharray="3 4"
            />
        </svg>
    );
}

export function CircleTick({invert}: {invert?: boolean}) {
    return (
        <svg
            viewBox="0 0 21 21"
            fill={invert ? 'currentColor' : 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10.5" cy="10.5" r="9.75" stroke="currentColor" strokeWidth="1.5" />
            <path
                d="M7 11L9.66667 14L15 8"
                stroke={invert ? 'white' : 'currentColor'}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

export function CircleCross() {
    return (
        <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.1065 23.1176C18.6882 23.1176 23.213 18.6142 23.213 13.0588C23.213 7.50349 18.6882 3 13.1065 3C7.52483 3 3 7.50349 3 13.0588C3 18.6142 7.52483 23.1176 13.1065 23.1176Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7183 16.7458C16.0572 17.0847 16.6067 17.0847 16.9455 16.7458C17.2844 16.4069 17.2844 15.8575 16.9455 15.5186L14.3774 12.9505L16.9458 10.382C17.2847 10.0432 17.2847 9.49371 16.9458 9.15482C16.6069 8.81593 16.0575 8.81593 15.7186 9.15482L13.1502 11.7233L10.5819 9.15494C10.243 8.81605 9.69353 8.81605 9.35464 9.15494C9.01575 9.49382 9.01575 10.0433 9.35464 10.3822L11.923 12.9505L9.35493 15.5185C9.01605 15.8574 9.01605 16.4068 9.35493 16.7457C9.69382 17.0846 10.2433 17.0846 10.5822 16.7457L13.1502 14.1777L15.7183 16.7458Z"
                fill="white"
            />
        </svg>
    );
}

export function CircleCrossFilled() {
    return (
        <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5002 23.514C19.0921 23.514 23.6252 19.0022 23.6252 13.4367C23.6252 7.87123 19.0921 3.3595 13.5002 3.3595C7.9083 3.3595 3.37518 7.87123 3.37518 13.4367C3.37518 19.0022 7.9083 23.514 13.5002 23.514Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7612 16.5774C16.0445 16.8608 16.5039 16.8608 16.7872 16.5774C17.0705 16.2941 17.0705 15.8347 16.7872 15.5514L14.64 13.4042L16.7875 11.2567C17.0708 10.9733 17.0708 10.514 16.7875 10.2306C16.5042 9.9473 16.0448 9.9473 15.7615 10.2306L13.614 12.3781L11.4667 10.2309C11.1834 9.94754 10.724 9.94754 10.4407 10.2309C10.1573 10.5142 10.1573 10.9736 10.4407 11.2569L12.5879 13.4042L10.441 15.5511C10.1576 15.8345 10.1576 16.2938 10.441 16.5772C10.7243 16.8605 11.1837 16.8605 11.467 16.5772L13.614 14.4302L15.7612 16.5774Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function CirclePlusIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.875 10.4995C19.875 5.32373 15.6758 1.12451 10.5 1.12451C5.32422 1.12451 1.125 5.32373 1.125 10.4995C1.125 15.6753 5.32422 19.8745 10.5 19.8745C15.6758 19.8745 19.875 15.6753 19.875 10.4995Z"
                stroke="#009865"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M10.5 6.59326V14.4058"
                stroke="#009865"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.406 10.5005H6.59351"
                stroke="#009865"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function PlusIcon() {
    return (
        <svg data-testid="plusIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M256 176v160M336 256H176"
            />
        </svg>
    );
}

export function MinusIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M336 256H176"
            />
        </svg>
    );
}

export function CircleLine() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8.5" cy="8.5" r="7.5" stroke="currentColor" strokeWidth="2" />
            <line
                x1="3.29289"
                y1="13.2929"
                x2="13.2929"
                y2="3.29289"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    );
}
export function LinkArrowIcon() {
    return (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.24524 7.80859L9.24524 2.7558L4.19245 2.7558"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M8.49992 3.37655L3.11963 8.75684"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function LinkOutIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 6.00001L16 1.00001M16 1.00001H11M16 1.00001L8.5 8.5M6.83333 1H5C3.59987 1 2.8998 1 2.36502 1.27248C1.89462 1.51217 1.51217 1.89462 1.27248 2.36502C1 2.8998 1 3.59987 1 5V12C1 13.4001 1 14.1002 1.27248 14.635C1.51217 15.1054 1.89462 15.4878 2.36502 15.7275C2.8998 16 3.59987 16 5 16H12C13.4001 16 14.1002 16 14.635 15.7275C15.1054 15.4878 15.4878 15.1054 15.7275 14.635C16 14.1002 16 13.4001 16 12V10.1667"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function ShoppingIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 11 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.1665 1.0835L0.541504 3.25016V10.8335C0.541504 11.1208 0.65564 11.3964 0.858805 11.5995C1.06197 11.8027 1.33752 11.9168 1.62484 11.9168H9.20817C9.49549 11.9168 9.77104 11.8027 9.9742 11.5995C10.1774 11.3964 10.2915 11.1208 10.2915 10.8335V3.25016L8.6665 1.0835H2.1665Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.541504 3.25H10.2915"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.58333 5.4165C7.58333 5.99114 7.35506 6.54224 6.94873 6.94857C6.5424 7.3549 5.9913 7.58317 5.41667 7.58317C4.84203 7.58317 4.29093 7.3549 3.8846 6.94857C3.47827 6.54224 3.25 5.99114 3.25 5.4165"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function HeadphonesIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.625 9.75V6.5C1.625 5.20707 2.13861 3.96709 3.05285 3.05285C3.96709 2.13861 5.20707 1.625 6.5 1.625C7.79293 1.625 9.03291 2.13861 9.94715 3.05285C10.8614 3.96709 11.375 5.20707 11.375 6.5V9.75"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.375 10.2918C11.375 10.5791 11.2609 10.8547 11.0577 11.0579C10.8545 11.261 10.579 11.3752 10.2917 11.3752H9.75C9.46268 11.3752 9.18713 11.261 8.98397 11.0579C8.7808 10.8547 8.66667 10.5791 8.66667 10.2918V8.66683C8.66667 8.37951 8.7808 8.10396 8.98397 7.9008C9.18713 7.69763 9.46268 7.5835 9.75 7.5835H11.375V10.2918ZM1.625 10.2918C1.625 10.5791 1.73914 10.8547 1.9423 11.0579C2.14547 11.261 2.42102 11.3752 2.70833 11.3752H3.25C3.53732 11.3752 3.81287 11.261 4.01603 11.0579C4.2192 10.8547 4.33333 10.5791 4.33333 10.2918V8.66683C4.33333 8.37951 4.2192 8.10396 4.01603 7.9008C3.81287 7.69763 3.53732 7.5835 3.25 7.5835H1.625V10.2918Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function LocationIcon() {
    return (
        <svg
            viewBox="0 0 23 23"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 2.15588C7.92874 2.15588 5.03128 4.91364 5.03128 8.31018C5.03128 12.2184 9.34378 18.4118 10.9273 20.5509C10.993 20.6412 11.0792 20.7147 11.1787 20.7654C11.2782 20.816 11.3883 20.8424 11.5 20.8424C11.6117 20.8424 11.7218 20.816 11.8214 20.7654C11.9209 20.7147 12.0071 20.6412 12.0728 20.5509C13.6563 18.4127 17.9688 12.2215 17.9688 8.31018C17.9688 4.91364 15.0713 2.15588 11.5 2.15588Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5 10.7812C12.6909 10.7812 13.6562 9.81586 13.6562 8.625C13.6562 7.43414 12.6909 6.46875 11.5 6.46875C10.3091 6.46875 9.34375 7.43414 9.34375 8.625C9.34375 9.81586 10.3091 10.7812 11.5 10.7812Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function HomeIcon() {
    return (
        <svg
            viewBox="0 0 20 20"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.12503 8.28088V17.4996C3.12503 17.6654 3.19088 17.8244 3.30809 17.9416C3.4253 18.0588 3.58427 18.1246 3.75003 18.1246H7.50003V12.8121C7.50003 12.5635 7.5988 12.325 7.77462 12.1492C7.95043 11.9734 8.18889 11.8746 8.43753 11.8746H11.5625C11.8112 11.8746 12.0496 11.9734 12.2254 12.1492C12.4013 12.325 12.5 12.5635 12.5 12.8121V18.1246H16.25C16.4158 18.1246 16.5748 18.0588 16.692 17.9416C16.8092 17.8244 16.875 17.6654 16.875 17.4996V8.28088"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 10.0007L10.4254 2.03191C10.2301 1.82566 9.77344 1.82332 9.57461 2.03191L1.25 10.0007"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.625 6.99207V2.49988H13.75V5.19519"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function SpannerIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 22 22"
            fill="none"
        >
            <path
                d="M17.4626 7.90587C17.1807 8.18596 16.7995 8.34316 16.4021 8.34316C16.0047 8.34316 15.6234 8.18596 15.3415 7.90587L14.0961 6.6604C13.8157 6.37854 13.6583 5.99716 13.6583 5.59962C13.6583 5.20207 13.8157 4.8207 14.0961 4.53884L16.9151 1.71837C16.9207 1.713 16.9249 1.70634 16.9272 1.69897C16.9296 1.69161 16.9301 1.68377 16.9287 1.67616C16.9273 1.66854 16.9241 1.66139 16.9193 1.65534C16.9144 1.6493 16.9082 1.64454 16.9011 1.64149C15.0814 0.861494 12.7222 1.25899 11.224 2.74634C9.7906 4.16899 9.70904 6.30884 10.234 8.17633C10.3086 8.44178 10.3084 8.72268 10.2335 8.98803C10.1586 9.25338 10.0118 9.49287 9.80935 9.68008L1.99998 16.8121C1.76957 17.0164 1.58339 17.2656 1.45286 17.5445C1.32234 17.8234 1.25025 18.1261 1.24101 18.4339C1.23177 18.7417 1.28558 19.0481 1.39914 19.3343C1.51269 19.6205 1.6836 19.8805 1.90133 20.0983C2.11907 20.316 2.37904 20.4869 2.66527 20.6005C2.95149 20.714 3.25792 20.7678 3.56571 20.7586C3.8735 20.7493 4.17615 20.6772 4.45504 20.5467C4.73394 20.4162 4.9832 20.23 5.18748 19.9996L12.3997 12.1715C12.5845 11.9725 12.82 11.8275 13.0809 11.7521C13.3419 11.6767 13.6184 11.6737 13.8809 11.7435C15.7343 12.2399 17.8437 12.1466 19.2584 10.7502C20.7814 9.25024 21.11 6.59899 20.3717 5.08165C20.3684 5.07502 20.3635 5.06927 20.3575 5.06488C20.3516 5.06049 20.3446 5.05758 20.3373 5.05639C20.33 5.0552 20.3225 5.05576 20.3154 5.05804C20.3084 5.06031 20.3019 5.06422 20.2967 5.06946L17.4626 7.90587Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M3.5 19.25C3.91421 19.25 4.25 18.9142 4.25 18.5C4.25 18.0858 3.91421 17.75 3.5 17.75C3.08579 17.75 2.75 18.0858 2.75 18.5C2.75 18.9142 3.08579 19.25 3.5 19.25Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function PadlockIcon() {
    return (
        <svg viewBox="0 0 11 13" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.49997 0.187988C4.78886 0.187988 4.10688 0.470476 3.60405 0.973308C3.10121 1.47614 2.81873 2.15813 2.81873 2.86924V4.63071H2.65622C1.62414 4.63071 0.787476 5.46738 0.787476 6.49946V10.9682C0.787476 12.0003 1.62414 12.837 2.65622 12.837H8.34372C9.3758 12.837 10.2125 12.0003 10.2125 10.9682V6.49946C10.2125 5.46738 9.3758 4.63071 8.34372 4.63071H8.18122V2.86924C8.18122 2.15813 7.89874 1.47614 7.3959 0.973308C6.89307 0.470476 6.21109 0.187988 5.49997 0.187988ZM7.50229 5.93071C7.51188 5.93113 7.52153 5.93134 7.53122 5.93134C7.54092 5.93134 7.55056 5.93113 7.56015 5.93071H8.34372C8.65783 5.93071 8.91247 6.18535 8.91247 6.49946V10.9682C8.91247 11.2823 8.65783 11.537 8.34372 11.537H2.65622C2.34211 11.537 2.08747 11.2823 2.08747 10.9682V6.49946C2.08747 6.18535 2.34211 5.93071 2.65622 5.93071H3.4398C3.44939 5.93113 3.45903 5.93134 3.46873 5.93134C3.47842 5.93134 3.48806 5.93113 3.49766 5.93071H7.50229ZM6.88122 4.63071V2.86924C6.88122 2.50291 6.7357 2.15158 6.47667 1.89255C6.21763 1.63351 5.8663 1.48799 5.49997 1.48799C5.13364 1.48799 4.78232 1.63351 4.52328 1.89255C4.26425 2.15158 4.11873 2.50291 4.11873 2.86924V4.63071H6.88122Z"
                fill="currentColor"
                fillOpacity="0.6"
            />
        </svg>
    );
}

export function InfoIcon() {
    return (
        <svg
            width="28"
            height="27"
            viewBox="0 0 28 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5751 23.4709C19.1568 23.4709 23.6816 18.9674 23.6816 13.4121C23.6816 7.85676 19.1568 3.35327 13.5751 3.35327C7.99346 3.35327 3.46863 7.85676 3.46863 13.4121C3.46863 18.9674 7.99346 23.4709 13.5751 23.4709Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9516 11.4133C11.5892 11.4133 11.2954 11.7071 11.2954 12.0696C11.2954 12.432 11.5892 12.7258 11.9516 12.7258H13.3954V17.1884H11.6564C11.2939 17.1884 11.0001 17.4822 11.0001 17.8446C11.0001 18.2071 11.2939 18.5009 11.6564 18.5009H16.1845C16.5469 18.5009 16.8407 18.2071 16.8407 17.8446C16.8407 17.4822 16.5469 17.1884 16.1845 17.1884H14.7079V12.0696C14.7079 11.7071 14.4141 11.4133 14.0516 11.4133H11.9516Z"
                fill="#0000BE"
            />
            <path
                d="M13.9204 10.1C13.7127 10.1 13.5097 10.0384 13.3371 9.92303C13.1644 9.80766 13.0298 9.64367 12.9503 9.45181C12.8709 9.25995 12.8501 9.04883 12.8906 8.84515C12.9311 8.64147 13.0311 8.45438 13.178 8.30754C13.3248 8.16069 13.5119 8.06069 13.7156 8.02018C13.9193 7.97966 14.1304 8.00045 14.3222 8.07993C14.5141 8.1594 14.6781 8.29398 14.7935 8.46665C14.9088 8.63932 14.9704 8.84232 14.9704 9.04999C14.9704 9.32847 14.8598 9.59554 14.6629 9.79245C14.466 9.98936 14.1989 10.1 13.9204 10.1Z"
                fill="#0000BE"
            />
        </svg>
    );
}
