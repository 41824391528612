/**
 * This is a flat version of a promise, similar to the response type of apollo's `useQuery`/`useMutation`.
 *
 * Compared to apollo's types, this type offers 2 unique advantages:
 * 1. It's guaranteed to only be in one of the 4 states at a time (pending/loading/errored/resolved).
 *    Apollo's types can be in a combination of these states. E.g. we can have both an error and data at the same time.
 *    This is occasionally useful, but it makes type narrowing much more complicated.
 *    A Promised value is always in just one of the 4 states, which makes it easier to use in the common case.
 *    (If you do need a superposition of states, then don't use Promised)
 * 2. It has functional style utilities to transform data, e.g. map and flatMap.
 */
export type Promised<T, E = any> = Readonly<
    (Pending | Loading | Errored<E> | Resolved<T>) & {
        /**
         * True if the value is actively being loaded.
         */
        loading: boolean;
        /**
         * True if the operation has settled. This means that we'll either have data or an error.
         */
        settled: boolean;
        /**
         * True if the operation hasn't started yet.
         */
        pending: boolean;
        /**
         * Maps a resolved value to another resolved value
         */
        map<T2>(fn: MapFn<T, T2>): Promised<T2, E>;
        /**
         * Maps a resolved value to another Promised value.
         */
        flatMap<T2, E2>(fn: FlatMapFn<T, E, T2, E2>): Promised<T2, E2 | E>;
    }
>;

type Pending = {data: void; error: void; loading: false; settled: false; pending: true};
type Loading = {data: void; error: void; loading: true; settled: false; pending: false};
type Errored<E> = {data: void; error: E; loading: false; settled: true; pending: false};
type Resolved<T> = {data: T; error: void; loading: false; settled: true; pending: false};

export const pending: Promised<never, never> = {
    loading: false,
    settled: false,
    pending: true,
    data: undefined,
    error: undefined,
    map: () => pending,
    flatMap: () => pending,
};

export const loading: Promised<never, never> = {
    loading: true,
    settled: false,
    pending: false,
    data: undefined,
    error: undefined,
    map: () => loading,
    flatMap: () => loading,
};

export function errored<E>(error: E): Promised<never, E> {
    const err: Promised<never, E> = {
        error,
        data: undefined,
        loading: false,
        settled: true,
        pending: false,
        map: () => err,
        flatMap: () => err,
    };
    return err;
}

export function resolved<T>(value: T): Promised<T, never> {
    return {
        data: value,
        error: undefined,
        loading: false,
        settled: true,
        pending: false,
        map: <T2>(mapFn: MapFn<T, T2>) => resolved(mapFn(value)),
        flatMap: <T2, E2>(mapFn: FlatMapFn<T, never, T2, E2>) => mapFn(value),
    };
}

/**
 * Converts a promised-like object to a promised value.
 *
 * Since the input may be a combination of states, we take the following priority:
 *
 * loading > error > data
 *
 * E.g. if `loading` is set, then this will always return a loading state, regardless if data or error is set.
 *
 * If none of the values are set, then the pending state is returned.
 */
export function promised<T, E = any>({
    data,
    error,
    loading: isLoading,
}: {
    data?: T;
    error?: E;
    loading: boolean;
}): Promised<T, E> {
    if (isLoading) {
        return loading;
    } else if (error) {
        return errored(error);
    } else if (data) {
        return resolved(data);
    } else {
        return pending;
    }
}

type MapFn<I, O> = (input: I) => O;
type FlatMapFn<I, EI, O, EO> = (input: I) => Promised<O, EI | EO>;
