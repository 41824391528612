import React from 'react';

type SliderProps = {
    value: number;
    min?: number;
    max?: number;
    step?: number;
    onChange: (value: number) => void;
    renderValue?: (value: number) => React.ReactNode;
};
export function Slider({value, onChange, min = 0, max = 100, step = 1, renderValue}: SliderProps) {
    const percent = ((value - min) * 100) / (max - min);
    return (
        <div>
            {renderValue != null && (
                <div className="relative h-5 mx-1.5">
                    <div
                        className="flex justify-center absolute w-0 h-0 overflow-visible"
                        style={{left: `${percent}%`}}
                    >
                        {renderValue(value)}
                    </div>
                </div>
            )}
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => onChange(parseInt(e.target.value))}
                style={
                    {
                        '--slider-value': `${value}%`,
                    } as any
                }
            />
        </div>
    );
}
