import React from 'react';
import iconUnicodes from 'carbon-react/lib/components/icon/icon-unicodes';

export type SvgIconProps = {
    type: keyof typeof iconUnicodes;
} & React.SVGProps<SVGTextElement>;

/**
 * Carbon icons for svg
 */
export function SvgIcon({type: type, ...props}: SvgIconProps) {
    return (
        <text {...props} fontFamily="CarbonIcons">
            {' '}
            {parseCssUnicode(iconUnicodes[type])}
        </text>
    );
}

function parseCssUnicode(c: string) {
    if (c[0] !== '\\') {
        console.warn(`Not a valid CSS unicode character: ${c}`);
        return c;
    }
    return String.fromCharCode(parseInt(c.slice(1), 16));
}
