import React from 'react';

import {DetailText} from './detail-text';
import {intercomTargetProps} from './intercom-target';
import {HStack} from './stack';
import {outlineColorClassnames} from './utils';

export type SubNavItemProps = {
    children: React.ReactNode;
    isSelected?: boolean;
    fillWhenSelected?: boolean;
    intercomTarget?: string;
    onChange?: (selected: boolean) => void;
};
export function SubNavItem({children, isSelected, intercomTarget, onChange}: SubNavItemProps) {
    let content = children;
    if (typeof children === 'string') {
        content = <DetailText>{children}</DetailText>;
    }
    return (
        <div
            className={`flex flex-row w-64 h-20 rounded-lg my-px bg-white border ${outlineColorClassnames()}`}
            onClick={() => {
                if (onChange != null) {
                    onChange(!isSelected);
                }
            }}
            {...intercomTargetProps(intercomTarget)}
        >
            <HStack align="center">
                <div className="flex-1 pl-5">{content}</div>
            </HStack>
        </div>
    );
}
