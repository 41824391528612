import React from 'react';
import classnames from 'classnames';

type StrongProps = {
    children: React.ReactNode;
    highlight?: boolean;
};
export function Strong({children, highlight}: StrongProps) {
    return (
        <strong
            className={classnames('font-heading transition-colors', {
                'text-yin-90': highlight,
            })}
        >
            {children}
        </strong>
    );
}
