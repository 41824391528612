import React from 'react';
import CarbonProvider from 'carbon-react/lib/components/carbon-provider';
import sageTheme from 'carbon-react/lib/style/themes/sage';
import './system.css';
import GlobalStyle from 'carbon-react/lib/style/global-style';

import {textColorClassnames} from './utils';

type SystemProps = {
    children: React.ReactNode;
};
export function System({children}: SystemProps) {
    return (
        <div className="carbon-provider-flex-wrapper">
            <CarbonProvider theme={sageTheme} validationRedesignOptIn>
                <GlobalStyle />
                <div className={`flex flex-1 ${textColorClassnames()}`}>{children}</div>
            </CarbonProvider>
        </div>
    );
}
