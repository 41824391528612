import React from 'react';

import {Text, textColorClassnames} from './utils';

type Heading5Props = {
    element?: keyof JSX.IntrinsicElements;
    children: React.ReactNode;
    colour?: Text;
};
export function Heading4({element = 'h4', colour = 'default', children}: Heading5Props) {
    return React.createElement(
        element,
        {
            className: `font-heading text-300 font-500 leading-400 ${textColorClassnames(colour)}`,
        },
        children,
    );
}
