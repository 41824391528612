import React from 'react';
import classnames from 'classnames';

import {Text, textColorClassnames} from './utils';

type DetailTextProps = {
    children: React.ReactNode;
    colour?: Text;
};

export function LinkText({children, colour = 'major-action'}: DetailTextProps) {
    return <span className={classnames('underline', textColorClassnames(colour))}>{children}</span>;
}
