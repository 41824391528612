import React from 'react';

import {Text, textColorClassnames} from './utils';

type Heading3Props = {
    element?: keyof JSX.IntrinsicElements;
    children: React.ReactNode;
    colour?: Text;
};
export function Heading3({element = 'h3', colour = 'default', children}: Heading3Props) {
    return React.createElement(
        element,
        {
            className: `font-heading text-400 font-500 leading-400 ${textColorClassnames(colour)}`,
        },
        children,
    );
}
