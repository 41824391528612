import React from 'react';
import classnames from 'classnames';

import {Divide, divideColorClassnames} from './utils';
import {intercomTargetProps} from './intercom-target';
import {SpacingClasses} from '../tokens/carbon';

type CommonStackProps = {
    children: React.ReactNode;
    condensed?: boolean;
    // Ideally we'd just take a spacing value, but for Tailwind's JIT to work we need to have full class names listed.
    spacingClass?: SpacingClasses;
    large?: boolean;
    xlarge?: boolean;
    align?: 'start' | 'center' | 'end' | 'stretch';
    justify?: 'start' | 'center' | 'end' | 'stretch' | 'between';
    seperator?: boolean;
    separatorColour?: Divide;
    full?: boolean;
    restrictedWidth?: boolean;
    intercomTarget?: string;
    testId?: string;
};
type VStackProps = CommonStackProps;
export function VStack({
    children,
    condensed,
    spacingClass: inputSpacingClass,
    large,
    xlarge,
    align = 'stretch',
    justify,
    seperator,
    full,
    restrictedWidth,
    separatorColour = 'default',
    intercomTarget,
    testId,
}: VStackProps) {
    const spacingClass = seperator
        ? 'divide-y'
        : inputSpacingClass
          ? inputSpacingClass
          : condensed
            ? 'space-y-3'
            : large
              ? 'space-y-10'
              : xlarge
                ? 'space-y-14'
                : 'space-y-5';
    return (
        <div
            data-testid={testId}
            className={classnames(
                `flex flex-col ${spacingClass}`,
                seperator && divideColorClassnames(separatorColour),
                {
                    'items-start': align === 'start',
                    'items-center': align === 'center',
                    'items-end': align === 'end',
                    'items-stretch': align === 'stretch',
                    'justify-between': justify === 'between',
                    'flex-1': full && !restrictedWidth,
                    'max-w-lg': restrictedWidth,
                },
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            {seperator
                ? React.Children.map(children, (child) => {
                      if (child) {
                          return (
                              <div
                                  className={classnames('first:pt-0 last:pb-0', {
                                      'py-2.5': !large,
                                      'py-5': large,
                                      'w-full px-4': full,
                                  })}
                              >
                                  {child}
                              </div>
                          );
                      } else {
                          // do nothing
                      }
                  })
                : children}
        </div>
    );
}

type HStackProps = CommonStackProps & {
    wrap?: boolean;
};
export function HStack({
    children,
    large,
    align = 'center',
    wrap = false,
    seperator,
    full = false,
    separatorColour = 'default',
    intercomTarget,
}: HStackProps) {
    return (
        <div
            className={classnames(
                'flex flex-row',
                seperator && divideColorClassnames(separatorColour),
                {
                    'space-x-5': !large,
                    'space-x-10': large,
                    'divide-x': seperator,
                    'items-start': align === 'start',
                    'items-center': align === 'center',
                    'items-end': align === 'end',
                    'items-stretch': align === 'stretch',
                    'flex-wrap': wrap,
                    'flex-1 justify-between': full,
                },
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            {children}
        </div>
    );
}
