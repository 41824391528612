import React from 'react';
import {useQuery, TypedDocumentNode, ApolloError} from '@apollo/client';

export function buildContext<T, P>(
    document: TypedDocumentNode<T, {[key: string]: never}>,
    loading: React.ReactNode,
    error: React.ReactElement<{err: ApolloError}>,
    getFragment: (data: T) => P,
) {
    const ctx = React.createContext<P>(undefined as any);
    const QueryDataProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
        const {loading: reqLoading, error: reqError, data} = useQuery(document);
        if (reqLoading) {
            return <>{loading}</>;
        }

        if (reqError) {
            return React.isValidElement(error) ? React.cloneElement(error, {err: reqError}) : null;
        }

        if (data) {
            return <ctx.Provider value={getFragment(data)}>{children}</ctx.Provider>;
        }

        return null;
    };

    function useQueryData() {
        return React.useContext(ctx);
    }

    return {
        ctx,
        QueryDataProvider,
        useQueryData,
    };
}
