import React from 'react';
import classnames from 'classnames';

import {bgColorClassnames, textColorClassnames} from './utils';

type ChipProps = {
    children: string | React.ReactNode;
    invert?: boolean;
};
export function Chip({children, invert = false}: ChipProps) {
    return (
        <div
            className={classnames(
                'inline-block rounded-2xl p-2',
                !invert && `${textColorClassnames()} ${bgColorClassnames('minor-action')}`,
                invert && `${textColorClassnames('white')} ${bgColorClassnames('black')}`,
            )}
        >
            {children}
        </div>
    );
}
