import React from 'react';
import {createRoot} from 'react-dom/client';

import {App} from './src/app';

const app = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(app!);

root.render(<App />);
