import React from 'react';
import {useField} from 'formik';

import {
    Input as DesignSystemInput,
    PasswordInput as DesignSystemPasswordInput,
    InputProps,
    FormControl,
    FormErrorMessage,
} from '@sphericsio/design-system';

export function Input({
    name,
    ...props
}: Pick<
    InputProps,
    | 'name'
    | 'placeholder'
    | 'icon'
    | 'onPressIcon'
    | 'type'
    | 'showLabel'
    | 'rightLabel'
    | 'label'
    | 'disableEnter'
>) {
    const [field, meta] = useField<string>({name});

    return (
        <FormControl isInvalid={meta.error != null}>
            <DesignSystemInput {...props} {...field} />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}

export function PasswordInput({name, ...props}: Pick<InputProps, 'name' | 'placeholder'>) {
    const [field, meta] = useField<string>({name});

    return (
        <FormControl isInvalid={meta.error != null}>
            <DesignSystemPasswordInput {...props} {...field} />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}
