import React from 'react';
import classnames from 'classnames';

import {bgColorClassnames, Border, Background, outlineColorClassnames} from './utils';
import {intercomTargetProps} from './intercom-target';

type PanelProps = {
    children: React.ReactNode;
    bg?: Background;
    flex?: boolean;
    border?: boolean;
    borderColor?: Border;
    intercomTarget?: string;
    testId?: string;
};
export function Panel({
    children,
    bg = 'white',
    flex = false,
    border = true,
    borderColor = 'default',
    intercomTarget,
    testId,
}: PanelProps) {
    return (
        <div
            data-testId={testId}
            className={classnames(
                'rounded-xl w-full p-5',
                bgColorClassnames(bg),
                border && `border ${outlineColorClassnames(borderColor)}`,
                {
                    'flex flex-1': flex,
                },
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            {children}
        </div>
    );
}
