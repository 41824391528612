import React from 'react';
import classnames from 'classnames';

import {FormControlContext} from './form-control';
import {DetailText} from './detail-text';
import {textColorClassnames} from './utils';

type FormErrorMessageProps = {
    children: React.ReactNode;
};
export function FormErrorMessage({children}: FormErrorMessageProps) {
    const formControlCtx = React.useContext(FormControlContext);
    return (
        <div
            className={classnames(
                'flex items-start transition-opacity h-6',
                textColorClassnames('error'),
                {
                    'opacity-0': !formControlCtx.isInvalid,
                    'opacity-100': formControlCtx.isInvalid,
                },
            )}
        >
            <DetailText inherit>{children}</DetailText>
        </div>
    );
}
