import React from 'react';
import classnames from 'classnames';

import {intercomTargetProps} from './intercom-target';
import {HStack, VStack} from './stack';
import {Background, Border, bgColorClassnames, outlineColorClassnames} from './utils';

export type NavBarItemProps = {
    children: React.ReactNode;
    highlightBg?: Background;
    highlightBorder?: Border;
    isSelected: boolean;
    intercomTarget?: string;
    onClick?: () => void;
};

// TODO fix colours
export function NavBarItem({
    children,
    isSelected,
    highlightBg = 'white',
    highlightBorder = 'minor-action',
    onClick,
    intercomTarget,
}: NavBarItemProps) {
    return (
        <div
            className={classnames(
                'text-sm desktop-large:text-base p-3',
                isSelected &&
                    `${bgColorClassnames(highlightBg)} ${outlineColorClassnames(highlightBorder)}`,
                {
                    'border-l-4 rounded-l': isSelected,
                },
            )}
            onClick={() => {
                if (onClick != null) {
                    onClick();
                }
            }}
            {...intercomTargetProps(intercomTarget)}
        >
            <HStack>{children}</HStack>
        </div>
    );
}

export type NavBarProps = {
    children: React.ReactNode;
    bg?: Background;
    intercomTarget?: string;
};

export function NavBar({children, bg = 'layout', intercomTarget}: NavBarProps) {
    return (
        <div
            className={classnames(
                'h-screen w-80 border-r border-gray-350 pt-8 pl-3',
                bgColorClassnames(bg),
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            <VStack>{children}</VStack>
        </div>
    );
}
