import React from 'react';
import {useField} from 'formik';

import {DetailText, FormControl, FormErrorMessage, HStack} from '@sphericsio/design-system';

type CheckboxProps = {
    name: string;
    children: React.ReactNode;
};
export function Checkbox({name, children}: CheckboxProps) {
    const [field, meta] = useField<string>({name});
    return (
        <FormControl isInvalid={meta.error != null}>
            <div className="mt-2.5">
                <HStack>
                    <input id={name} type="checkbox" {...field} />
                    <label htmlFor={name}>
                        <DetailText>{children}</DetailText>
                    </label>
                </HStack>
            </div>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}
