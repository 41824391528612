import classnames from 'classnames';

export type Background =
    | 'black'
    | 'navigation'
    | 'secondary'
    | 'major-action'
    | 'minor-action'
    | 'disabled'
    | 'error'
    | 'info'
    | 'transparent'
    | 'alert'
    | 'table-header'
    | 'white'
    | 'nav-item'
    | 'modal-bg'
    | 'layout';

export type Divide = 'default' | 'white' | 'error' | 'info' | 'transparent' | 'alert';

export type Text =
    | 'default'
    | 'white'
    | 'error'
    | 'info'
    | 'transparent'
    | 'alert'
    | 'secondary'
    | 'disabled'
    | 'success'
    | 'tooltip'
    | 'minor-action'
    | 'logo'
    | 'black'
    | 'button'
    | 'major-action'
    | 'excluded'
    | 'text-selected';

export type Border =
    | 'default'
    | 'navigation'
    | 'major-action'
    | 'minor-action'
    | 'disabled'
    | 'input'
    | 'white'
    | 'error'
    | 'info'
    | 'transparent'
    | 'button'
    | 'excluded'
    | 'pill-default'
    | 'alert';

export function bgColorClassnames(bg: Background) {
    return classnames({
        'bg-black': bg === 'black',
        'bg-yin-90': bg === 'navigation' || bg === 'secondary',
        'bg-action-major-500': bg === 'major-action',
        'bg-action-minor-50': bg === 'minor-action',
        'bg-action-minor-100': bg === 'disabled',
        'bg-semantic-info-500': bg === 'info',
        'bg-semantic-caution-500': bg === 'alert',
        'bg-semantic-negative-500': bg === 'error',
        'bg-white': bg === 'white',
        'bg-transparent': bg === 'transparent',
        'bg-utility-major-10': bg === 'layout',
        'bg-utility-major-500': bg === 'table-header',
        'bg-utility-major-800': bg === 'modal-bg',
        'bg-gray-700': bg === 'nav-item',
    });
}

export function outlineColorClassnames(bg: Border = 'default') {
    return classnames({
        'border-utility-major-75': bg === 'default',
        'border-yin-90': bg === 'navigation',
        'border-semantic-negative-500': bg === 'error',
        'border-semantic-info-500': bg === 'info',
        'border-semantic-caution-500': bg === 'alert',
        'border-action-major-500': bg === 'major-action' || bg === 'button',
        'border-action-minor-100': bg === 'disabled',
        'border-action-minor-200': bg === 'pill-default',
        'border-action-minor-400': bg === 'input',
        'border-action-minor-500': bg === 'minor-action',
        'border-yang-100 text-white': bg === 'white',
        'border-transparent': bg === 'transparent',
        'border-utility-major-200': bg === 'excluded',
    });
}

export function textColorClassnames(color: Text = 'default') {
    return classnames({
        'text-yin-90': color === 'default',
        'text-yin-65': color === 'secondary',
        'text-black': color === 'black',
        'text-yin-30': color === 'disabled',
        'text-yang-100': color === 'white',
        'text-semantic-negative-500': color === 'error',
        'text-semantic-info-500': color === 'info',
        'text-semantic-caution-500': color === 'alert',
        'text-transparent': color === 'transparent',
        'text-logo': color === 'logo',
        'text-action-minor-500': color === 'tooltip' || color === 'minor-action',
        'text-action-major-500':
            color === 'success' || color === 'button' || color === 'major-action',
        'text-action-minor-600': color === 'text-selected',
        'text-utility-major-200': color === 'excluded',
    });
}

export function divideColorClassnames(divide: Divide = 'default') {
    return classnames({
        'divide-utility-major-75': divide === 'default',
        'divide-white': divide === 'white',
        'divide-semantic-negative-500': divide === 'error',
        'divide-semantic-info-500': divide === 'info',
        'divide-transparent': divide === 'transparent',
        'divide-semantic-caution-500': divide === 'alert',
    });
}

export function getTextColorBasedOnOutline(outline: Border): Text {
    switch (outline) {
        // no corresponding text color defined
        case 'navigation':
        case 'input':
        case 'pill-default':
            return 'default';

        default:
            return outline;
    }
}
