import {parseConfig} from '@sphericsio/config';

import defaultConfig from './default';

export default function generateConfig(deployEnv: string) {
    let deployEnvConfig;
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        deployEnvConfig = require(`./${deployEnv}`).default;
    } catch (ignored) {
        console.error(ignored);
    } //eslint-disable-line no-empty

    return parseConfig(defaultConfig, deployEnvConfig);
}
