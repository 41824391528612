import React from 'react';

type LoadingSpinnerProps = {
    size?: number;
    items?: number;
};
export function LoadingSpinner({items = 12, size = 24}: LoadingSpinnerProps) {
    const rotateIncrement = 360 / items;
    const animationBeginDelta = 1 / items;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g fill="currentColor">
                {new Array(items).fill(null).map((_, i) => (
                    <g key={i} transform={`rotate(${rotateIncrement * i} 50 50)`}>
                        <rect x={47.5} y={1.5} rx={5.5} ry={5.25} width={12} height={28}>
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin={`${-1 + animationBeginDelta * (i + 1)}s`}
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                ))}
            </g>
        </svg>
    );
}
