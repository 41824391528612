import React, {RefObject} from 'react';

export function useOnScreen(target: RefObject<HTMLElement>, options?: IntersectionObserverInit) {
    const [entry, setEntry] = React.useState<IntersectionObserverEntry>();
    const observer = new IntersectionObserver(([entry]) => setEntry(entry), options);

    React.useEffect(() => {
        if (target.current) {
            observer.observe(target.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [target]);

    return entry;
}
