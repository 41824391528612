import React from 'react';
import {useField} from 'formik';

import {FormControl, Toggle as DesignSystemToggle} from '@sphericsio/design-system';

export type ToggleProps = {
    name: string;
    onLabel: string;
    offLabel: string;
    testId?: string;
};

export function Toggle({name, onLabel, offLabel, testId}: ToggleProps) {
    const [field, _meta, helpers] = useField<boolean>({name});
    return (
        <FormControl>
            <DesignSystemToggle
                value={field.value}
                onToggle={() => helpers.setValue(!field.value)}
                onLabel={onLabel}
                offLabel={offLabel}
                testId={testId}
            />
        </FormControl>
    );
}
