import React from 'react';
import {chain, set, difference, flatMap} from 'lodash';
import classnames from 'classnames';

import {
    CategorisationThemeIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CloseIcon,
    ConditionalWrapper,
    DetailText,
    getCategorisationTheme,
    HStack,
    Panel,
    PlusIcon,
    SearchInput,
    Strong,
    TruncatedStringWithEllipsis,
} from '@sphericsio/design-system';

type UserCategorisationHierarchyItem = {
    key: string;
    taxonomyKey: string;
    taxonomyTermKey: string;
    title: string;
    children: UserCategorisationHierarchyItem[];
};

type UserCategorisationHierarchyItemWithParent = UserCategorisationHierarchyItem & {
    parent?: UserCategorisationHierarchyItemWithParent;
};

function collectParents(
    parents: UserCategorisationHierarchyItemWithParent[],
    parent?: UserCategorisationHierarchyItemWithParent,
): UserCategorisationHierarchyItemWithParent[] {
    if (parent == null) {
        return parents;
    } else if (parent.parent == null) {
        return [parent, ...parents];
    } else {
        return collectParents([parent, ...parents], parent.parent);
    }
}

type FlattenedItems = {
    parents: UserCategorisationHierarchyItemWithParent[];
    items: UserCategorisationHierarchyItemWithParent[];
};

type UserCategorisationLevelDisplayProps = {
    items: UserCategorisationHierarchyItemWithParent[];
    onClickItem: (item: UserCategorisationHierarchyItemWithParent) => void;
    selectItem: (item: UserCategorisationHierarchyItemWithParent) => void;
    canSelectAllLevels?: boolean;
};
function UserCategorisationLevelDisplay({
    items,
    onClickItem,
    selectItem,
    canSelectAllLevels,
}: UserCategorisationLevelDisplayProps) {
    const groupedByParents = chain(items)
        .map((item) => ({parents: collectParents([], item.parent), item}))
        .reduce((memo, item) => {
            if (memo.length === 0) {
                return [{parents: item.parents, items: [item.item]}];
            }
            for (const group of memo) {
                if (difference(item.parents, group.parents).length === 0) {
                    group.items.push(item.item);
                    return memo;
                }
            }

            memo.push({parents: item.parents, items: [item.item]});

            return memo;
        }, [] as FlattenedItems[])
        .value();

    const getThemeFromGroup = (group: FlattenedItems) => {
        const root = group.parents.find(({taxonomyKey}) => taxonomyKey === 'general');
        return getCategorisationTheme(root?.taxonomyTermKey ?? 'default');
    };

    return (
        <ul className="divide-y flex-1 px-px h-72 overflow-y-auto">
            {groupedByParents.map((group, index) => {
                const els: React.ReactNode[] = [];
                if (group.parents.length > 0) {
                    els.push(
                        <li key={index} className="py-3.5 first:pt-0 last:pb-0">
                            <HStack condensed>
                                <CategorisationThemeIcon theme={getThemeFromGroup(group)} />
                                <div className="flex flex-1 flex-row align-center space-x-2">
                                    {chain(group.parents)
                                        .compact()
                                        .flatMap((item, index) => [
                                            <Strong key={`${item.taxonomyKey}-title`}>
                                                <ConditionalWrapper
                                                    condition={index > 0}
                                                    wrapper={(children) => (
                                                        <TruncatedStringWithEllipsis>
                                                            {children}
                                                        </TruncatedStringWithEllipsis>
                                                    )}
                                                >
                                                    {item.title}
                                                </ConditionalWrapper>
                                            </Strong>,
                                            <div
                                                className="w-4 h-4"
                                                key={`${item.taxonomyKey}-separator`}
                                            >
                                                <ChevronRightIcon />
                                            </div>,
                                        ])
                                        .dropRight(1)
                                        .value()}
                                </div>
                            </HStack>
                        </li>,
                    );
                }

                els.push(
                    group.items.map((item) => (
                        <li
                            key={item.taxonomyTermKey}
                            className={classnames('py-3.5 first:pt-0 last:pb-0', {
                                'cursor-pointer':
                                    item.children.length > 0 ||
                                    (item.children.length === 0 && !canSelectAllLevels),
                            })}
                            onClick={() => {
                                onClickItem(item);
                            }}
                        >
                            <HStack condensed>
                                {item.parent == null ? (
                                    <CategorisationThemeIcon
                                        theme={getCategorisationTheme(item.taxonomyTermKey)}
                                    />
                                ) : canSelectAllLevels ? (
                                    <div
                                        onClick={() => selectItem(item)}
                                        className="cursor-pointer"
                                    >
                                        <DetailText bold colour="minor-action">
                                            <div className="w-6 h-6 flex-center">
                                                <PlusIcon />
                                            </div>
                                            <DetailText size="small" inherit>
                                                ADD
                                            </DetailText>
                                        </DetailText>
                                    </div>
                                ) : (
                                    <div className="pl-8" />
                                )}
                                {item.parent ? (
                                    <div className="flex-1">
                                        <DetailText colour="secondary">{item.title}</DetailText>
                                    </div>
                                ) : (
                                    <div className="flex-1">
                                        <DetailText bold>{item.title}</DetailText>
                                    </div>
                                )}
                                <div className="flex self-center w-5 h-5">
                                    {item.children.length === 0 ? (
                                        !canSelectAllLevels && <PlusIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </div>
                            </HStack>
                        </li>
                    )),
                );

                return els;
            })}
        </ul>
    );
}

function findMatches(
    results: UserCategorisationHierarchyItemWithParent[],
    searchString: string,
    item: UserCategorisationHierarchyItemWithParent,
): UserCategorisationHierarchyItemWithParent[] {
    let localResults = results;

    if (item.children.length > 0) {
        localResults = localResults.concat(
            ...flatMap(item.children, (child) => findMatches([], searchString, child)),
        );
    } else if (item.title.toLowerCase().includes(searchString.toLowerCase())) {
        localResults = localResults.concat(item);
    }

    return localResults;
}

const LEVELS = 3;
const INITIAL_SELECTED_ITEMS: (UserCategorisationHierarchyItem | undefined)[] = new Array(
    LEVELS - 1,
).fill(undefined);

type UserCategorisationMenuProps = {
    data: UserCategorisationHierarchyItem[];
    onClose: () => void;
    onSelect: (item: UserCategorisationHierarchyItem) => void;
    canSelectAllLevels?: boolean;
};
export function UserCategorisationMenu({
    data,
    onClose,
    onSelect,
    canSelectAllLevels,
}: UserCategorisationMenuProps) {
    const hierarchy = React.useMemo(() => {
        const addParentToItem = (
            item: UserCategorisationHierarchyItemWithParent,
            parent?: UserCategorisationHierarchyItemWithParent,
        ) => {
            item.parent = parent;
            item.children = item.children.map((child) => addParentToItem(child, item));
            return item;
        };
        return data.map((item) => addParentToItem(item));
    }, [data]);
    const [selectedItems, setSelectedItems] = React.useState(INITIAL_SELECTED_ITEMS);
    const [level, setLevel] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] =
        React.useState<UserCategorisationHierarchyItemWithParent[]>();
    const onPerformSearch = React.useRef((query: string) => {
        setSearchTerm(query);
    });

    React.useEffect(() => {
        if (searchTerm === '') {
            setSearchResults(undefined);
        } else {
            setSearchResults(flatMap(hierarchy, (item) => findMatches([], searchTerm, item)));
        }
    }, [hierarchy, searchTerm]);
    return (
        <Panel>
            <div className="flex flex-1 justify-between items-center">
                <div
                    className="flex overflow-hidden"
                    style={{cursor: level === 0 ? 'default' : 'pointer'}}
                >
                    <div
                        className="flex-1 pointer-events-none transition-opacity duration-150"
                        style={{opacity: level === 0 ? 1 : 0}}
                    >
                        <DetailText bold>Select from the below</DetailText>
                    </div>
                    <div
                        className="flex-1 transition-opacity duration-150"
                        style={{marginLeft: '-100%', opacity: level === 0 ? 0 : 1}}
                        onClick={() => {
                            setLevel((level) => Math.max(0, level - 1));
                            setSearchTerm('');
                        }}
                    >
                        <div className="flex items-center space-x-2">
                            <div className="w-4 h-4">
                                <ChevronLeftIcon />
                            </div>
                            <DetailText colour="secondary">Back</DetailText>
                        </div>
                    </div>
                </div>
                <div
                    data-testid="categorisationMenuClose"
                    className="w-5 h-5 cursor-pointer"
                    onClick={onClose}
                >
                    <CloseIcon />
                </div>
            </div>
            <div className="my-3">
                <SearchInput
                    name="search"
                    throttleSearch={false}
                    minCharacters={0}
                    onPerformSearch={onPerformSearch.current}
                />
            </div>
            {searchResults == null && (
                <div className="w-96 overflow-x-hidden">
                    <div
                        className="flex transition-transform duration-500"
                        style={{
                            width: `${100 * LEVELS}%`,
                            transform: `translateX(calc((-100% / ${LEVELS}) * ${level}))`,
                        }}
                    >
                        <UserCategorisationLevelDisplay
                            canSelectAllLevels={canSelectAllLevels}
                            items={hierarchy}
                            onClickItem={(item) => {
                                setSelectedItems(set([...INITIAL_SELECTED_ITEMS], 0, item));
                                setLevel(1);
                            }}
                            selectItem={(item) => onSelect(item)}
                        />
                        {selectedItems.map((item, index) => (
                            <ul
                                key={item == null ? index : item.taxonomyKey}
                                className="divide-y flex-1 px-px"
                            >
                                {item != null && (
                                    <UserCategorisationLevelDisplay
                                        canSelectAllLevels={canSelectAllLevels}
                                        items={item.children}
                                        onClickItem={(item) => {
                                            if (item.children.length > 0) {
                                                setSelectedItems((items) =>
                                                    set([...items], level, item),
                                                );
                                                setLevel((level) => level + 1);
                                            }

                                            if (item.children.length === 0 && !canSelectAllLevels) {
                                                onSelect(item);
                                            }
                                        }}
                                        selectItem={(item) => onSelect(item)}
                                    />
                                )}
                            </ul>
                        ))}
                    </div>
                </div>
            )}
            {searchResults != null && searchResults.length > 0 && (
                <div className="w-96">
                    <UserCategorisationLevelDisplay
                        items={searchResults}
                        onClickItem={(item) => {
                            onSelect(item);
                        }}
                        selectItem={(item) => onSelect(item)}
                    />
                </div>
            )}
            {searchResults != null && searchResults.length === 0 && (
                <div className="flex w-96 h-72 items-start">
                    <DetailText>
                        <span className="inline-block mt-2">
                            We couldn&apos;t find any results for &quot;{searchTerm}&quot;.
                        </span>
                    </DetailText>
                </div>
            )}
        </Panel>
    );
}
