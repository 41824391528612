import React, {forwardRef} from 'react';

type ParagraphProps = {
    children: React.ReactNode;
};

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(function Paragraph(
    {children},
    ref,
) {
    return (
        <p className="font-body text-100 font-400 leading-500" ref={ref}>
            {children}
        </p>
    );
});

export function DivParagraph({children}: ParagraphProps) {
    return <div className="font-body text-100 font-400 leading-500">{children}</div>;
}
