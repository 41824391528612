import React from 'react';
import classnames from 'classnames';

import {Text, textColorClassnames} from './utils';

type DetailTextProps = {
    children: React.ReactNode;
    colour?: Text;
};
export function Header({children, colour = 'default'}: DetailTextProps) {
    return (
        <div
            className={classnames(
                'font-heading text-700 leading-400 font-500',
                textColorClassnames(colour),
            )}
        >
            {children}
        </div>
    );
}
