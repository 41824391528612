import {usePollQuery} from '@sphericsio/mvp-ui-common';

import {graphql} from '../graphql/generated';
import {Exact, FetchBackgroundExecutionStatusQuery} from '../graphql/generated/graphql';

const FetchBackgroundExecutionStatusDocument = graphql(/* GraphQL */ `
    query FetchBackgroundExecutionStatus($id: ID!) {
        fetchBackgroundExecution(id: $id) {
            status
            completed_at
        }
    }
`);

type Options = {
    pollInterval?: number;
    maxNumberOfTries?: number;
};
export function useBackgroundExecutionStatus(id?: string, opts: Options = {}) {
    return usePollQuery<
        FetchBackgroundExecutionStatusQuery,
        Exact<{
            id: string;
        }>
    >(
        FetchBackgroundExecutionStatusDocument,
        (data) => data.fetchBackgroundExecution?.completed_at != null,
        {...opts, startImmediately: id != null},
        {id},
    );
}
