import React from 'react';
import classnames from 'classnames';

import {intercomTargetProps} from './intercom-target';
import {ButtonLabel} from './button-label';
import {Background, Border, Text, bgColorClassnames, outlineColorClassnames} from './utils';

export type PillProps = {
    borderSelected?: Border;
    borderUnselected?: Border;
    bgSelected?: Background;
    bgUnselected?: Background;
    textSelected?: Text;
    textUnselected?: Text;
    children: React.ReactNode;
    isDisabled?: boolean;
    isSelected?: boolean;
    onChange?: (selected: boolean) => void;
    small?: boolean;
    wide?: boolean;
    fillWhenSelected?: boolean;
    fullRounded?: boolean;
    flex?: boolean;
    intercomTarget?: string;
};
export function Pill({
    borderSelected = 'minor-action',
    borderUnselected = 'default',
    bgSelected = 'minor-action',
    bgUnselected = 'white',
    textSelected = 'text-selected',
    textUnselected = 'default',
    isDisabled,
    isSelected,
    onChange,
    small = false,
    wide = false,
    fillWhenSelected = false,
    fullRounded = true,
    flex = false,
    intercomTarget,
    children,
}: PillProps) {
    let content = children;
    if (typeof children === 'string') {
        content = (
            <ButtonLabel colour={isSelected ? textSelected : textUnselected}>
                {children}
            </ButtonLabel>
        );
    }

    return (
        <button
            className={classnames(
                'py-2 text-center outline-none',
                !isSelected &&
                    `${outlineColorClassnames(borderUnselected)} border my-px ${bgColorClassnames(
                        bgUnselected,
                    )}`,
                isSelected &&
                    !fillWhenSelected &&
                    `${outlineColorClassnames(borderSelected)} border-4 bg-transparent`,
                isSelected &&
                    fillWhenSelected &&
                    `${outlineColorClassnames(borderSelected)} border-4 ${bgColorClassnames(
                        bgSelected,
                    )}`,
                {
                    'cursor-default': isDisabled,
                    'cursor-pointer': !isDisabled && onChange != null,
                    'px-5': small,
                    'px-10': !small && !wide,
                    'px-8': wide,
                    'w-32': small && !flex,
                    'w-48': !small && !flex,
                    'flex flex-1 justify-center': flex,
                    'rounded-full': fullRounded,
                    'rounded-2xl': !fullRounded,
                },
            )}
            onClick={() => {
                if (onChange != null) {
                    onChange(!isSelected);
                }
            }}
            {...intercomTargetProps(intercomTarget)}
        >
            {content}
        </button>
    );
}
