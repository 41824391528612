import React from 'react';
import classnames from 'classnames';

import {bgColorClassnames, textColorClassnames} from './utils';

export type TabItemConfig = {
    id: string;
    label: string;
};
type TabBarProps = {
    tabs: TabItemConfig[];
    selectedTab: TabItemConfig;
    onSelectTab: (tabItem: TabItemConfig) => void;
};

export function TabBar({tabs, onSelectTab, selectedTab}: TabBarProps) {
    return (
        <nav className="relative">
            <div className="inline-block">
                <ul className="grid grid-flow-col auto-cols-fr">
                    {tabs.map((tabItem) => {
                        const selected = tabItem.id === selectedTab.id;
                        return (
                            <li
                                className={classnames(
                                    'text-center relative px-5 py-2 cursor-pointer font-body transition-colors',

                                    textColorClassnames(selected ? 'minor-action' : 'secondary'),
                                    bgColorClassnames(selected ? 'minor-action' : 'transparent'),
                                )}
                                key={tabItem.id}
                                onClick={() => {
                                    onSelectTab(tabItem);
                                }}
                            >
                                {tabItem.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="absolute left-0 right-0 bottom-0 border-b-2" />
        </nav>
    );
}
