import React from 'react';

type Heading1Props = {
    element?: keyof JSX.IntrinsicElements;
    children: React.ReactNode;
};
export function Heading1({children, element = 'h1'}: Heading1Props) {
    return React.createElement(
        element,
        {
            className: 'font-bold text-600 font-700 leading-400',
        },
        children,
    );
}
