import React from 'react';

type ConditionalWrapperProps = {
    children: React.ReactNode;
    condition: boolean;
    wrapper: (children: React.ReactNode) => React.ReactNode;
};
export function ConditionalWrapper({children, condition, wrapper}: ConditionalWrapperProps) {
    return condition ? <>{wrapper(children)}</> : <>{children}</>;
}
