import React from 'react';

type StickyHeaderListProps<T, H> = {
    headers: H[];
    items: T[][];
    renderHeader(header: H): React.ReactNode;
    renderItem(item: T, header: H): React.ReactNode;
    headerKey?: (header: H) => string;
    itemKey?: (item: T) => string;
};
export function StickyHeaderList<T, H>({
    headers,
    items,
    renderHeader,
    renderItem,
    headerKey,
    itemKey,
}: StickyHeaderListProps<T, H>) {
    return (
        <div className="h-full overflow-y-auto space-y-5">
            {headers.map((header, headerIndex) => (
                <div key={headerKey == null ? headerIndex : headerKey(header)}>
                    <div className="sticky top-0 bg-utility-major-500 text-yang-100 rounded-xl p-5">
                        {renderHeader(header)}
                    </div>
                    <ul className="divide-y mt-5 ml-2.5">
                        {items[headerIndex].map((item, itemIndex) => (
                            <li
                                className="pl-2.5 py-2.5"
                                key={itemKey == null ? itemIndex : itemKey(item)}
                            >
                                {renderItem(item, header)}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}
