import React from 'react';
import classnames from 'classnames';

import {Text, textColorClassnames} from './utils';

type ButtonLabelSize = 'large' | 'medium';
type ButtonLabelProps = {
    children: React.ReactNode;
    size?: ButtonLabelSize;
    inherit?: boolean;
    colour?: Text;
};
export function ButtonLabel({
    children,
    size = 'large',
    colour = 'white',
    inherit = false,
}: ButtonLabelProps) {
    return (
        <div
            className={classnames('font-heading', !inherit && textColorClassnames(colour), {
                'text-200 font-500 leading-500': size === 'large',
                'text-100 font-500 leading-500': size === 'medium',
            })}
        >
            {children}
        </div>
    );
}
