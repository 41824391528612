import React, {useRef} from 'react';
import classnames from 'classnames';

import {DetailText, LinkOutIcon, useLink, VStack} from '.';
import {useOnScreen} from '../hooks/on-screen';
import {Heading5} from './heading-5';
import {LinkText} from './link-text';
import {bgColorClassnames, outlineColorClassnames, textColorClassnames} from './utils';

export type Placement = 'left' | 'bottom' | 'right' | 'top';
export type Align = 'start' | 'end' | 'center';

type WrapperProps = {
    children: React.ReactNode;
    place: Placement;
};
function Wrapper({children, place}: WrapperProps) {
    return (
        <div
            className={classnames('flex flex-none', {
                'items-center': place === 'left' || place === 'right',
                'place-content-center': place === 'bottom' || place === 'top',
            })}
        >
            {children}
        </div>
    );
}

type TooltipProps = {
    children: React.ReactNode;
    tooltipText: string;
    place?: Placement;
    align?: Align;
    linkHref?: string;
    linkText?: string;
    header?: string;
};
export function Tooltip(props: TooltipProps) {
    const {children, place, linkHref} = props;
    const [isActive, setIsActive] = React.useState(false);
    const clickable = linkHref != null;
    let delayHideLoop: ReturnType<typeof setTimeout> | null = null;

    const handleMouseEnter = () => {
        if (clickable && delayHideLoop) {
            clearTimeout(delayHideLoop);
        }
        setIsActive(true);
    };

    const handleMouseLeave = () => {
        if (clickable) {
            delayHideLoop = setTimeout(() => setIsActive(false), 200);
        } else {
            setIsActive(false);
        }
    };

    return (
        <Wrapper place={place ?? 'right'}>
            {isActive && (
                <TooltipLayout
                    {...props}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                />
            )}
            <div
                className="w-full h-full"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </Wrapper>
    );
}

type TooltipLayoutProps = TooltipProps & {
    handleMouseEnter: () => void;
    handleMouseLeave: () => void;
};
function TooltipLayout({
    tooltipText,
    place = 'right',
    align = 'start',
    linkHref,
    linkText,
    header,
    handleMouseEnter,
    handleMouseLeave,
}: TooltipLayoutProps) {
    const linkProps = useLink({
        href: linkHref,
        openInNewWindow: true,
    });
    const tooltipRef = useRef<HTMLDivElement>(null);
    const intersectionEntry = useOnScreen(tooltipRef);

    const placement = React.useMemo(() => {
        if (!intersectionEntry) {
            return place;
        }
        const {isIntersecting, intersectionRatio} = intersectionEntry;

        if (isIntersecting && intersectionRatio < 1 && place == 'bottom') {
            return 'top';
        }
        if (isIntersecting && intersectionRatio < 1 && place == 'top') {
            return 'bottom';
        }
        return place;
    }, [intersectionEntry]);

    return (
        <div
            ref={tooltipRef}
            className={classnames(
                'absolute min-w-min z-10 p-7 flex rounded cursor-default border',
                bgColorClassnames('white'),
                outlineColorClassnames(),
                {
                    'top-full mt-2': placement === 'bottom',
                    'left-full ml-2': placement === 'right',
                    'right-full mr-2': placement === 'left',
                    'bottom-full mb-2': placement === 'top',
                    'top-0': align === 'start' && (placement === 'right' || placement === 'left'),
                    'bottom-0': align === 'end' && (placement === 'right' || placement === 'left'),
                    'right-0': align === 'end' && (placement === 'top' || placement === 'bottom'),
                    'left-0': align === 'start' && (placement === 'top' || placement === 'bottom'),
                    'items-center w-96': placement === 'left' || placement === 'right',
                    'place-content-center w-96': placement === 'bottom' || placement === 'top',
                },
            )}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={classnames(
                    'h-2 w-2 absolute transform rotate-45',
                    bgColorClassnames('white'),
                    outlineColorClassnames(),
                    {
                        '-left-1 border-l border-b':
                            placement === 'right' &&
                            (align === 'start' || align === 'center' || align === 'end'),
                        '-right-1 border-r border-t':
                            placement === 'left' &&
                            (align === 'start' || align === 'center' || align === 'end'),
                        '-top-1 border-t border-l':
                            placement === 'bottom' &&
                            (align === 'start' || align === 'center' || align === 'end'),
                        '-bottom-1 border-b border-r':
                            placement === 'top' &&
                            (align === 'start' || align === 'center' || align === 'end'),
                        'right-2':
                            align === 'end' &&
                            (placement === 'left' || placement === 'top' || placement === 'bottom'),
                        'left-2':
                            align === 'start' &&
                            (placement === 'right' ||
                                placement === 'top' ||
                                placement === 'bottom'),
                        'top-2':
                            align === 'start' && (placement === 'right' || placement === 'left'),
                        'bottom-2':
                            align === 'end' && (placement === 'right' || placement === 'left'),
                    },
                )}
            />

            <DetailText>
                <VStack>
                    {header && (
                        <span className="mb-5">
                            <Heading5>{header}</Heading5>
                        </span>
                    )}
                    <div className="whitespace-pre-line">{tooltipText}</div>
                    {linkHref && linkText && (
                        <div className="flex space-x-2 mt-5">
                            <div
                                className={`shrink-0 w-4 h-4 mt-1 ${textColorClassnames('button')}`}
                            >
                                <LinkOutIcon />
                            </div>
                            <LinkText>
                                <a {...linkProps}>{linkText}</a>
                            </LinkText>
                        </div>
                    )}
                </VStack>
            </DetailText>
        </div>
    );
}
